const TestEnvironmentBanner = () => {
  if (
    process.env.NEXT_PUBLIC_ROOT_URL !== "https://moviautos.com" &&
    process.env.NEXT_PUBLIC_ROOT_URL !== "http://localhost:3000"
  ) {
    return (
      <div className="bg-amber-500 py-2 px-4 text-white">
        <span>
          Ambiente de pruebas: Para ir al sitio oficial visite{" "}
          <a href="https://moviautos.com" className="underline" target="_blank">
            moviautos.com
          </a>
        </span>
      </div>
    );
  }
};

export { TestEnvironmentBanner };
