import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Color = {
  __typename?: "Color";
  exterior?: Maybe<Scalars["String"]>;
  interior?: Maybe<Scalars["String"]>;
};

export type ColorInput = {
  exterior?: InputMaybe<Scalars["String"]>;
  interior?: InputMaybe<Scalars["String"]>;
};

export type Company = {
  __typename?: "Company";
  about?: Maybe<Scalars["String"]>;
  banner?: Maybe<Scalars["String"]>;
  companyId?: Maybe<Scalars["String"]>;
  legalName?: Maybe<Scalars["String"]>;
  location?: Maybe<Location>;
  logo?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type CompanyInput = {
  about?: InputMaybe<Scalars["String"]>;
  banner?: InputMaybe<Scalars["String"]>;
  companyId?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<LocationInput>;
  logo?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type Discount = {
  __typename?: "Discount";
  _id: Scalars["ID"];
  createdDate: Scalars["String"];
  discountCode: Scalars["String"];
  percentOff: Scalars["Float"];
  purchaseIds: Array<Scalars["String"]>;
  userEmails?: Maybe<Array<Maybe<Scalars["String"]>>>;
  validUntil: Scalars["String"];
};

export type FavoriteSearch = {
  __typename?: "FavoriteSearch";
  _id: Scalars["ID"];
  createdDate?: Maybe<Scalars["String"]>;
  filters: VehicleFiltersType;
  lastVisited?: Maybe<Scalars["String"]>;
  notify?: Maybe<Scalars["Boolean"]>;
  query: Scalars["String"];
  userId?: Maybe<Scalars["ID"]>;
};

export type Financing = {
  __typename?: "Financing";
  _id: Scalars["ID"];
  brand: Scalars["String"];
  currentYear: Scalars["Int"];
  downPayment: Scalars["Float"];
  model: Scalars["String"];
  term: Scalars["Int"];
  years: Array<Scalars["Int"]>;
};

export type FinancingData = {
  __typename?: "FinancingData";
  downPayment?: Maybe<Scalars["Float"]>;
  entities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  financingCurrency?: Maybe<Scalars["String"]>;
  monthlyFee?: Maybe<Scalars["Float"]>;
  term?: Maybe<Scalars["Float"]>;
};

export type FinancingDataInput = {
  downPayment?: InputMaybe<Scalars["Float"]>;
  entities?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  financingCurrency?: InputMaybe<Scalars["String"]>;
  monthlyFee?: InputMaybe<Scalars["Float"]>;
  term?: InputMaybe<Scalars["Float"]>;
};

export type HistoricPrice = {
  __typename?: "HistoricPrice";
  _id: Scalars["ID"];
  basePriceCRC: Scalars["Float"];
  basePriceUSD: Scalars["Float"];
  brand: Scalars["String"];
  category?: Maybe<Scalars["String"]>;
  cc?: Maybe<Scalars["Int"]>;
  fuel?: Maybe<Scalars["String"]>;
  img?: Maybe<Scalars["String"]>;
  model: Scalars["String"];
  plate?: Maybe<Scalars["Int"]>;
  priceCRCMinus5Percent: Scalars["Float"];
  priceUSDMinus5Percent: Scalars["Float"];
  province?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  transmission?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type LastVisited = {
  __typename?: "LastVisited";
  _id: Scalars["ID"];
  items?: Maybe<Array<Maybe<Vehicle>>>;
  userId: Scalars["ID"];
};

export type Location = {
  __typename?: "Location";
  address?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  municipality?: Maybe<Scalars["String"]>;
  province?: Maybe<Scalars["String"]>;
};

export type LocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  province?: InputMaybe<Scalars["String"]>;
};

export type ModelAvgPriceResponse = {
  __typename?: "ModelAvgPriceResponse";
  avgPriceCRC?: Maybe<Scalars["Float"]>;
  avgPriceUSD?: Maybe<Scalars["Float"]>;
  category?: Maybe<Scalars["String"]>;
  maxPriceCRC?: Maybe<Scalars["Float"]>;
  maxPriceUSD?: Maybe<Scalars["Float"]>;
  minPriceCRC?: Maybe<Scalars["Float"]>;
  minPriceUSD?: Maybe<Scalars["Float"]>;
};

export type ModelYearlyAvgMeta = {
  __typename?: "ModelYearlyAvgMeta";
  x?: Maybe<Scalars["Float"]>;
  y?: Maybe<Scalars["Float"]>;
};

export type ModelYearlyAvgResponse = {
  __typename?: "ModelYearlyAvgResponse";
  results?: Maybe<Array<Maybe<ModelYearlyAvgMeta>>>;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["String"]>;
  acceptTermsAndConditions?: Maybe<User>;
  addFavoriteSearch?: Maybe<FavoriteSearch>;
  addFinancing?: Maybe<Financing>;
  addHistoricPrice?: Maybe<HistoricPrice>;
  addItemToWatchlist?: Maybe<Watchlist>;
  addPurchase?: Maybe<Scalars["Boolean"]>;
  addVehicle?: Maybe<Vehicle>;
  applyPurchase?: Maybe<Purchase>;
  bumpVehicle?: Maybe<Vehicle>;
  deactivateUserAccount?: Maybe<User>;
  deleteAllFavoriteSearches?: Maybe<Scalars["Boolean"]>;
  deleteFavoriteSearch?: Maybe<FavoriteSearch>;
  deleteFinancing?: Maybe<Financing>;
  deleteHistoricPrice?: Maybe<HistoricPrice>;
  deleteLastVisited?: Maybe<LastVisited>;
  deletePurchase?: Maybe<Purchase>;
  deleteVehicle?: Maybe<Vehicle>;
  deleteWatchlist?: Maybe<Watchlist>;
  reactivateUserAccount?: Maybe<User>;
  removeItemFromLastVisited?: Maybe<LastVisited>;
  removeItemFromWatchlist?: Maybe<Watchlist>;
  renewVehicle?: Maybe<Vehicle>;
  updateFavoriteSearch?: Maybe<FavoriteSearch>;
  updateFinancing?: Maybe<Financing>;
  updateHistoricPrice?: Maybe<HistoricPrice>;
  updateLastVisited?: Maybe<LastVisited>;
  updateUser?: Maybe<User>;
  updateVehicle?: Maybe<Vehicle>;
  verifyUserId?: Maybe<User>;
};

export type MutationAcceptTermsAndConditionsArgs = {
  _id: Scalars["ID"];
  type: Scalars["String"];
};

export type MutationAddFavoriteSearchArgs = {
  notify?: InputMaybe<Scalars["Boolean"]>;
  query: Scalars["String"];
};

export type MutationAddHistoricPriceArgs = {
  title: Scalars["String"];
};

export type MutationAddItemToWatchlistArgs = {
  itemId?: InputMaybe<Scalars["ID"]>;
};

export type MutationAddPurchaseArgs = {
  discountApplied?: InputMaybe<Scalars["Float"]>;
  discountCodeApplied?: InputMaybe<Scalars["String"]>;
  isRenew?: InputMaybe<Scalars["Boolean"]>;
  pricePayed: Scalars["Float"];
  quantity: Scalars["Int"];
  referenceId?: InputMaybe<Scalars["String"]>;
  renewType?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  type: PurchaseTypeEnum;
  userId: Scalars["String"];
};

export type MutationAddVehicleArgs = {
  vehicle: VehicleInput;
};

export type MutationApplyPurchaseArgs = {
  _id: Scalars["ID"];
  resourceId: Scalars["ID"];
};

export type MutationBumpVehicleArgs = {
  _id: Scalars["ID"];
};

export type MutationDeactivateUserAccountArgs = {
  _id: Scalars["ID"];
};

export type MutationDeleteFavoriteSearchArgs = {
  _id: Scalars["ID"];
};

export type MutationDeleteFinancingArgs = {
  _id: Scalars["ID"];
};

export type MutationDeleteHistoricPriceArgs = {
  _id: Scalars["ID"];
};

export type MutationDeletePurchaseArgs = {
  _id: Scalars["ID"];
};

export type MutationDeleteVehicleArgs = {
  _id: Scalars["ID"];
  deletionCause?: InputMaybe<Scalars["String"]>;
};

export type MutationReactivateUserAccountArgs = {
  _id: Scalars["ID"];
};

export type MutationRemoveItemFromLastVisitedArgs = {
  itemId: Scalars["ID"];
};

export type MutationRemoveItemFromWatchlistArgs = {
  itemId?: InputMaybe<Scalars["ID"]>;
};

export type MutationRenewVehicleArgs = {
  _id: Scalars["ID"];
  tier: Scalars["String"];
};

export type MutationUpdateFavoriteSearchArgs = {
  _id: Scalars["ID"];
  notify?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationUpdateFinancingArgs = {
  _id: Scalars["ID"];
};

export type MutationUpdateHistoricPriceArgs = {
  _id: Scalars["ID"];
};

export type MutationUpdateLastVisitedArgs = {
  items: Array<InputMaybe<Scalars["ID"]>>;
};

export type MutationUpdateUserArgs = {
  user: UserInput;
};

export type MutationUpdateVehicleArgs = {
  vehicle: VehicleInput;
};

export type MutationVerifyUserIdArgs = {
  _id: Scalars["ID"];
  faceWithIdUrl?: InputMaybe<Scalars["String"]>;
  idUrl?: InputMaybe<Scalars["String"]>;
};

export type Permit = {
  __typename?: "Permit";
  label?: Maybe<Scalars["Boolean"]>;
  taxes?: Maybe<Scalars["Boolean"]>;
  technical?: Maybe<Scalars["Boolean"]>;
};

export type PermitInput = {
  label?: InputMaybe<Scalars["Boolean"]>;
  taxes?: InputMaybe<Scalars["Boolean"]>;
  technical?: InputMaybe<Scalars["Boolean"]>;
};

export type Purchase = {
  __typename?: "Purchase";
  _id: Scalars["ID"];
  createdDate?: Maybe<Scalars["String"]>;
  dateApplied?: Maybe<Scalars["String"]>;
  discountApplied?: Maybe<Scalars["Float"]>;
  discountCodeApplied?: Maybe<Scalars["String"]>;
  isRenew?: Maybe<Scalars["Boolean"]>;
  pricePayed: Scalars["Float"];
  referenceId?: Maybe<Scalars["String"]>;
  resource?: Maybe<Vehicle>;
  resourceId?: Maybe<Scalars["String"]>;
  type: PurchaseTypeEnum;
  userId: Scalars["String"];
};

export enum PurchaseTypeEnum {
  Bump = "bump",
  Collection = "collection",
  Max = "max",
  Max_5 = "max_5",
  Max_10 = "max_10",
  Max_15 = "max_15",
  Max_20 = "max_20",
  Max_25 = "max_25",
}

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["String"]>;
  getAllPurchases?: Maybe<Array<Maybe<Purchase>>>;
  getAllUsers?: Maybe<Array<Maybe<User>>>;
  getConnectReferrerInfo?: Maybe<User>;
  getDiscount?: Maybe<Discount>;
  getFavoriteSearch?: Maybe<FavoriteSearch>;
  getFavoriteSearchesByVehicle?: Maybe<Array<Maybe<FavoriteSearch>>>;
  getFeaturedVehicles?: Maybe<Vehicle>;
  getFinancing?: Maybe<Financing>;
  getFinancings?: Maybe<Array<Maybe<Financing>>>;
  getHistoricPrice?: Maybe<HistoricPrice>;
  getHistoricPrices?: Maybe<Array<Maybe<HistoricPrice>>>;
  getLastVisiteds?: Maybe<Array<Maybe<LastVisited>>>;
  getModelAvgPrice?: Maybe<ModelAvgPriceResponse>;
  getModelYearlyAvg?: Maybe<ModelYearlyAvgResponse>;
  getModelsAutocomplete?: Maybe<Array<Maybe<HistoricPrice>>>;
  getPurchase?: Maybe<Purchase>;
  getSimilarVehicles?: Maybe<Array<Maybe<Vehicle>>>;
  getUser?: Maybe<User>;
  getUserFavoriteSearches?: Maybe<Array<Maybe<FavoriteSearch>>>;
  getUserLastVisited?: Maybe<Array<Maybe<Vehicle>>>;
  getUserPurchases?: Maybe<Array<Maybe<Purchase>>>;
  getUserWatchlist?: Maybe<Array<Maybe<Vehicle>>>;
  getVehicle?: Maybe<Vehicle>;
  getVehicles?: Maybe<SearchVehiclesResponse>;
  getWatchlists?: Maybe<Array<Maybe<Watchlist>>>;
};

export type QueryGetConnectReferrerInfoArgs = {
  username?: InputMaybe<Scalars["String"]>;
};

export type QueryGetDiscountArgs = {
  discountCode: Scalars["String"];
};

export type QueryGetFavoriteSearchArgs = {
  _id: Scalars["ID"];
};

export type QueryGetFavoriteSearchesByVehicleArgs = {
  vehicle?: InputMaybe<VehicleInput>;
};

export type QueryGetFinancingArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  vehicleId?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetFinancingsArgs = {
  brand?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetHistoricPriceArgs = {
  _id: Scalars["ID"];
};

export type QueryGetLastVisitedsArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryGetModelAvgPriceArgs = {
  brand: Scalars["String"];
  model: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type QueryGetModelYearlyAvgArgs = {
  brand: Scalars["String"];
  model: Scalars["String"];
};

export type QueryGetModelsAutocompleteArgs = {
  brand: Scalars["String"];
  query?: InputMaybe<Scalars["String"]>;
};

export type QueryGetPurchaseArgs = {
  _id: Scalars["ID"];
};

export type QueryGetSimilarVehiclesArgs = {
  _id?: InputMaybe<Scalars["ID"]>;
  filters?: InputMaybe<VehicleFilters>;
};

export type QueryGetUserArgs = {
  _id?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type QueryGetUserWatchlistArgs = {
  connect?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetVehicleArgs = {
  _id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type QueryGetVehiclesArgs = {
  connect: Scalars["Boolean"];
  filters?: InputMaybe<VehicleFilters>;
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  random?: InputMaybe<Scalars["Boolean"]>;
  searchText?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<Sort>;
  userSubscriptionTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type QueryGetWatchlistsArgs = {
  query?: InputMaybe<Scalars["String"]>;
};

export type SearchVehiclesMeta = {
  __typename?: "SearchVehiclesMeta";
  pageSize?: Maybe<Scalars["Int"]>;
  total?: Maybe<Scalars["Int"]>;
};

export type SearchVehiclesResponse = {
  __typename?: "SearchVehiclesResponse";
  data?: Maybe<Array<Maybe<Vehicle>>>;
  meta?: Maybe<SearchVehiclesMeta>;
};

export enum Sort {
  Highest = "highest",
  Latest = "latest",
  Lowest = "lowest",
}

export type Subscription = {
  __typename?: "Subscription";
  endDate?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SubscriptionInput = {
  endDate?: InputMaybe<Scalars["String"]>;
  startDate?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type Term = {
  __typename?: "Term";
  acceptedVersion?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type TermInput = {
  acceptedVersion?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  _id?: Maybe<Scalars["ID"]>;
  accountStatus?: Maybe<Scalars["String"]>;
  company?: Maybe<Company>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  emailVerified?: Maybe<Scalars["Boolean"]>;
  faceWithIdUrl?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  idNumber?: Maybe<Scalars["String"]>;
  idUrl?: Maybe<Scalars["String"]>;
  idVerified?: Maybe<Scalars["Boolean"]>;
  image?: Maybe<Scalars["String"]>;
  joinDate?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  lastUpdated?: Maybe<Scalars["String"]>;
  primaryPhone?: Maybe<Scalars["String"]>;
  secondLastName?: Maybe<Scalars["String"]>;
  secondaryPhone?: Maybe<Scalars["String"]>;
  subscription?: Maybe<Subscription>;
  terms?: Maybe<Array<Maybe<Term>>>;
  username?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  accountStatus?: InputMaybe<Scalars["String"]>;
  company?: InputMaybe<CompanyInput>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  faceWithIdUrl?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  idNumber?: InputMaybe<Scalars["String"]>;
  idUrl?: InputMaybe<Scalars["String"]>;
  idVerified?: InputMaybe<Scalars["Boolean"]>;
  image?: InputMaybe<Scalars["String"]>;
  joinDate?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  lastUpdated?: InputMaybe<Scalars["String"]>;
  primaryPhone?: InputMaybe<Scalars["String"]>;
  secondLastName?: InputMaybe<Scalars["String"]>;
  secondaryPhone?: InputMaybe<Scalars["String"]>;
  subscription?: InputMaybe<SubscriptionInput>;
  terms?: InputMaybe<Array<InputMaybe<TermInput>>>;
  username?: InputMaybe<Scalars["String"]>;
};

export type Vehicle = {
  __typename?: "Vehicle";
  _id: Scalars["ID"];
  brand?: Maybe<Scalars["String"]>;
  bumpCount?: Maybe<Scalars["Int"]>;
  category?: Maybe<Scalars["String"]>;
  cc?: Maybe<Scalars["Int"]>;
  color?: Maybe<Color>;
  condition?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["Float"]>;
  currency?: Maybe<Scalars["String"]>;
  cylinders?: Maybe<Scalars["Int"]>;
  deletionCause?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  drivetrain?: Maybe<Scalars["String"]>;
  equipment?: Maybe<Array<Maybe<Scalars["String"]>>>;
  expires?: Maybe<Scalars["Float"]>;
  financingData?: Maybe<FinancingData>;
  fuel?: Maybe<Scalars["String"]>;
  guarantee?: Maybe<Scalars["Boolean"]>;
  guaranteeDescription?: Maybe<Scalars["String"]>;
  hidePrice?: Maybe<Scalars["Boolean"]>;
  includesFinancing?: Maybe<Scalars["Boolean"]>;
  isContactInfoPublic?: Maybe<Scalars["Boolean"]>;
  isFeatured?: Maybe<Scalars["Boolean"]>;
  kwh?: Maybe<Scalars["Float"]>;
  lastUpdated?: Maybe<Scalars["Float"]>;
  listingTier?: Maybe<Scalars["String"]>;
  location?: Maybe<Location>;
  mileage?: Maybe<Scalars["Int"]>;
  mileageUnit?: Maybe<Scalars["String"]>;
  minPrice?: Maybe<Scalars["Float"]>;
  model?: Maybe<Scalars["String"]>;
  modelVersion?: Maybe<Scalars["String"]>;
  negotiable?: Maybe<Scalars["Boolean"]>;
  negotiationStatus?: Maybe<Scalars["String"]>;
  permit?: Maybe<Scalars["Boolean"]>;
  pictures?: Maybe<Array<Maybe<Scalars["String"]>>>;
  plate?: Maybe<Scalars["String"]>;
  platePending?: Maybe<Scalars["Boolean"]>;
  previousTier?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Float"]>;
  priceIncludesImportTaxes?: Maybe<Scalars["Boolean"]>;
  priceIncludesTransfer?: Maybe<Scalars["Boolean"]>;
  purchaseId?: Maybe<Scalars["ID"]>;
  range?: Maybe<Scalars["Int"]>;
  rangeMileageUnit?: Maybe<Scalars["String"]>;
  receivesAnotherVehicle?: Maybe<Scalars["Boolean"]>;
  renewCount?: Maybe<Scalars["Int"]>;
  renewedDate?: Maybe<Scalars["Float"]>;
  rtv?: Maybe<Scalars["Boolean"]>;
  sharedFee?: Maybe<Scalars["Float"]>;
  slug?: Maybe<Scalars["String"]>;
  soh?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  tags?: Maybe<Scalars["String"]>;
  thumbs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  totalFee?: Maybe<Scalars["Float"]>;
  transmission?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars["ID"]>;
  year?: Maybe<Scalars["Int"]>;
  yearStr?: Maybe<Scalars["String"]>;
  youtubeLink?: Maybe<Scalars["String"]>;
};

export type VehicleFilters = {
  brand?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  cc?: InputMaybe<Scalars["Int"]>;
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  currency?: InputMaybe<Scalars["String"]>;
  cylinders?: InputMaybe<Scalars["Int"]>;
  drivetrain?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fuel?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  kwh?: InputMaybe<Scalars["Int"]>;
  listingTier?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  model?: InputMaybe<Scalars["String"]>;
  priceMax?: InputMaybe<Scalars["Int"]>;
  priceMin?: InputMaybe<Scalars["Int"]>;
  province?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  range?: InputMaybe<Scalars["Int"]>;
  soh?: InputMaybe<Scalars["Int"]>;
  transmission?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  username?: InputMaybe<Scalars["String"]>;
  yearMax?: InputMaybe<Scalars["Int"]>;
  yearMin?: InputMaybe<Scalars["Int"]>;
};

export type VehicleFiltersType = {
  __typename?: "VehicleFiltersType";
  brand?: Maybe<Array<Maybe<Scalars["String"]>>>;
  category?: Maybe<Array<Maybe<Scalars["String"]>>>;
  cc?: Maybe<Scalars["Int"]>;
  condition?: Maybe<Array<Maybe<Scalars["String"]>>>;
  currency?: Maybe<Scalars["String"]>;
  cylinders?: Maybe<Scalars["Int"]>;
  drivetrain?: Maybe<Array<Maybe<Scalars["String"]>>>;
  fuel?: Maybe<Array<Maybe<Scalars["String"]>>>;
  kwh?: Maybe<Scalars["Int"]>;
  listingTier?: Maybe<Array<Maybe<Scalars["String"]>>>;
  model?: Maybe<Scalars["String"]>;
  priceMax?: Maybe<Scalars["Int"]>;
  priceMin?: Maybe<Scalars["Int"]>;
  province?: Maybe<Array<Maybe<Scalars["String"]>>>;
  range?: Maybe<Scalars["Int"]>;
  search?: Maybe<Array<Maybe<Scalars["String"]>>>;
  soh?: Maybe<Scalars["Int"]>;
  transmission?: Maybe<Array<Maybe<Scalars["String"]>>>;
  username?: Maybe<Scalars["String"]>;
  yearMax?: Maybe<Scalars["Int"]>;
  yearMin?: Maybe<Scalars["Int"]>;
};

export type VehicleInput = {
  _id?: InputMaybe<Scalars["ID"]>;
  brand?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  cc?: InputMaybe<Scalars["Int"]>;
  color?: InputMaybe<ColorInput>;
  condition?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<Scalars["String"]>;
  cylinders?: InputMaybe<Scalars["Int"]>;
  description?: InputMaybe<Scalars["String"]>;
  drivetrain?: InputMaybe<Scalars["String"]>;
  equipment?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  financingData?: InputMaybe<FinancingDataInput>;
  fuel?: InputMaybe<Scalars["String"]>;
  guarantee?: InputMaybe<Scalars["Boolean"]>;
  guaranteeDescription?: InputMaybe<Scalars["String"]>;
  hidePrice?: InputMaybe<Scalars["Boolean"]>;
  includesFinancing?: InputMaybe<Scalars["Boolean"]>;
  isContactInfoPublic?: InputMaybe<Scalars["Boolean"]>;
  kwh?: InputMaybe<Scalars["Int"]>;
  listingTier?: InputMaybe<Scalars["String"]>;
  mileage?: InputMaybe<Scalars["Int"]>;
  mileageUnit?: InputMaybe<Scalars["String"]>;
  minPrice?: InputMaybe<Scalars["Float"]>;
  model?: InputMaybe<Scalars["String"]>;
  modelVersion?: InputMaybe<Scalars["String"]>;
  municipality?: InputMaybe<Scalars["String"]>;
  negotiable?: InputMaybe<Scalars["Boolean"]>;
  negotiationStatus?: InputMaybe<Scalars["String"]>;
  permit?: InputMaybe<Scalars["Boolean"]>;
  pictures?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  plate?: InputMaybe<Scalars["String"]>;
  platePending?: InputMaybe<Scalars["Boolean"]>;
  price?: InputMaybe<Scalars["Float"]>;
  priceIncludesImportTaxes?: InputMaybe<Scalars["Boolean"]>;
  priceIncludesTransfer?: InputMaybe<Scalars["Boolean"]>;
  province?: InputMaybe<Scalars["String"]>;
  purchaseId?: InputMaybe<Scalars["ID"]>;
  range?: InputMaybe<Scalars["Int"]>;
  rangeMileageUnit?: InputMaybe<Scalars["String"]>;
  receivesAnotherVehicle?: InputMaybe<Scalars["Boolean"]>;
  rtv?: InputMaybe<Scalars["Boolean"]>;
  sharedFee?: InputMaybe<Scalars["Float"]>;
  slug?: InputMaybe<Scalars["String"]>;
  soh?: InputMaybe<Scalars["Int"]>;
  tags?: InputMaybe<Scalars["String"]>;
  totalFee?: InputMaybe<Scalars["Float"]>;
  transmission?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["ID"]>;
  year?: InputMaybe<Scalars["Int"]>;
  youtubeLink?: InputMaybe<Scalars["String"]>;
};

export type Watchlist = {
  __typename?: "Watchlist";
  _id: Scalars["ID"];
  items?: Maybe<Array<Maybe<Vehicle>>>;
  userId: Scalars["ID"];
};

export type VehicleListDataFragment = {
  __typename?: "Vehicle";
  _id: string;
  pictures?: Array<string | null> | null;
  thumbs?: Array<string | null> | null;
  brand?: string | null;
  model?: string | null;
  price?: number | null;
  hidePrice?: boolean | null;
  currency?: string | null;
  year?: number | null;
  category?: string | null;
  mileageUnit?: string | null;
  mileage?: number | null;
  fuel?: string | null;
  transmission?: string | null;
  drivetrain?: string | null;
  condition?: string | null;
  receivesAnotherVehicle?: boolean | null;
  negotiable?: boolean | null;
  tags?: string | null;
  listingTier?: string | null;
  createdDate?: number | null;
  expires?: number | null;
  youtubeLink?: string | null;
  isFeatured?: boolean | null;
  slug?: string | null;
  user?: {
    __typename?: "User";
    _id?: string | null;
    username?: string | null;
    displayName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    image?: string | null;
    idVerified?: boolean | null;
  } | null;
  location?: {
    __typename?: "Location";
    country?: string | null;
    province?: string | null;
    municipality?: string | null;
  } | null;
  financingData?: {
    __typename?: "FinancingData";
    entities?: Array<string | null> | null;
  } | null;
};

export type AddFavoriteSearchMutationVariables = Exact<{
  query: Scalars["String"];
  notify?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddFavoriteSearchMutation = {
  __typename?: "Mutation";
  addFavoriteSearch?: {
    __typename?: "FavoriteSearch";
    _id: string;
    query: string;
    notify?: boolean | null;
  } | null;
};

export type DeleteFavoriteSearchMutationVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type DeleteFavoriteSearchMutation = {
  __typename?: "Mutation";
  deleteFavoriteSearch?: { __typename?: "FavoriteSearch"; _id: string } | null;
};

export type UpdateFavoriteSearchMutationVariables = Exact<{
  _id: Scalars["ID"];
  notify?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateFavoriteSearchMutation = {
  __typename?: "Mutation";
  updateFavoriteSearch?: {
    __typename?: "FavoriteSearch";
    _id: string;
    query: string;
    notify?: boolean | null;
  } | null;
};

export type DeleteAllFavoriteSearchesMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteAllFavoriteSearchesMutation = {
  __typename?: "Mutation";
  deleteAllFavoriteSearches?: boolean | null;
};

export type UpdateLastVisitedMutationVariables = Exact<{
  items: Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>;
}>;

export type UpdateLastVisitedMutation = {
  __typename?: "Mutation";
  updateLastVisited?: {
    __typename?: "LastVisited";
    _id: string;
    items?: Array<{ __typename?: "Vehicle"; _id: string } | null> | null;
  } | null;
};

export type RemoveItemFromLastVisitedMutationVariables = Exact<{
  itemId: Scalars["ID"];
}>;

export type RemoveItemFromLastVisitedMutation = {
  __typename?: "Mutation";
  removeItemFromLastVisited?: {
    __typename?: "LastVisited";
    _id: string;
    items?: Array<{ __typename?: "Vehicle"; _id: string } | null> | null;
  } | null;
};

export type DeleteLastVisitedMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteLastVisitedMutation = {
  __typename?: "Mutation";
  deleteLastVisited?: { __typename?: "LastVisited"; _id: string } | null;
};

export type AddPurchaseMutationVariables = Exact<{
  referenceId: Scalars["String"];
  userId: Scalars["String"];
  resourceId?: InputMaybe<Scalars["String"]>;
  type: PurchaseTypeEnum;
  quantity: Scalars["Int"];
  isRenew?: InputMaybe<Scalars["Boolean"]>;
  renewType?: InputMaybe<Scalars["String"]>;
  pricePayed: Scalars["Float"];
  discountApplied?: InputMaybe<Scalars["Float"]>;
  discountCodeApplied?: InputMaybe<Scalars["String"]>;
}>;

export type AddPurchaseMutation = {
  __typename?: "Mutation";
  addPurchase?: boolean | null;
};

export type ApplyPurchaseMutationVariables = Exact<{
  _id: Scalars["ID"];
  resourceId: Scalars["ID"];
}>;

export type ApplyPurchaseMutation = {
  __typename?: "Mutation";
  applyPurchase?: {
    __typename?: "Purchase";
    _id: string;
    resourceId?: string | null;
  } | null;
};

export type DeletePurchaseMutationVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type DeletePurchaseMutation = {
  __typename?: "Mutation";
  deletePurchase?: { __typename?: "Purchase"; _id: string } | null;
};

export type UpdateUserMutationVariables = Exact<{
  user: UserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    _id?: string | null;
    username?: string | null;
    email?: string | null;
    idVerified?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    secondLastName?: string | null;
    primaryPhone?: string | null;
    secondaryPhone?: string | null;
    image?: string | null;
    company?: {
      __typename?: "Company";
      name?: string | null;
      legalName?: string | null;
      companyId?: string | null;
      about?: string | null;
      logo?: string | null;
      location?: {
        __typename?: "Location";
        province?: string | null;
        municipality?: string | null;
        address?: string | null;
      } | null;
    } | null;
  } | null;
};

export type VerifyUserIdMutationVariables = Exact<{
  _id: Scalars["ID"];
  idUrl?: InputMaybe<Scalars["String"]>;
  faceWithIdUrl?: InputMaybe<Scalars["String"]>;
}>;

export type VerifyUserIdMutation = {
  __typename?: "Mutation";
  verifyUserId?: {
    __typename?: "User";
    _id?: string | null;
    idVerified?: boolean | null;
    idNumber?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    secondLastName?: string | null;
  } | null;
};

export type DeactivateUserAccountMutationVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type DeactivateUserAccountMutation = {
  __typename?: "Mutation";
  deactivateUserAccount?: { __typename?: "User"; _id?: string | null } | null;
};

export type ReactivateUserAccountMutationVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type ReactivateUserAccountMutation = {
  __typename?: "Mutation";
  reactivateUserAccount?: { __typename?: "User"; _id?: string | null } | null;
};

export type AcceptTermsAndConditionsMutationVariables = Exact<{
  _id: Scalars["ID"];
  type: Scalars["String"];
}>;

export type AcceptTermsAndConditionsMutation = {
  __typename?: "Mutation";
  acceptTermsAndConditions?: {
    __typename?: "User";
    _id?: string | null;
    terms?: Array<{
      __typename?: "Term";
      type?: string | null;
      acceptedVersion?: string | null;
    } | null> | null;
  } | null;
};

export type AddVehicleMutationVariables = Exact<{
  vehicle: VehicleInput;
}>;

export type AddVehicleMutation = {
  __typename?: "Mutation";
  addVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    slug?: string | null;
    brand?: string | null;
    model?: string | null;
    year?: number | null;
    thumbs?: Array<string | null> | null;
  } | null;
};

export type UpdateVehicleMutationVariables = Exact<{
  vehicle: VehicleInput;
}>;

export type UpdateVehicleMutation = {
  __typename?: "Mutation";
  updateVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    slug?: string | null;
  } | null;
};

export type DeleteVehicleMutationVariables = Exact<{
  _id: Scalars["ID"];
  deletionCause: Scalars["String"];
}>;

export type DeleteVehicleMutation = {
  __typename?: "Mutation";
  deleteVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    slug?: string | null;
  } | null;
};

export type BumpVehicleMutationVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type BumpVehicleMutation = {
  __typename?: "Mutation";
  bumpVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    slug?: string | null;
    bumpCount?: number | null;
  } | null;
};

export type RenewVehicleMutationVariables = Exact<{
  _id: Scalars["ID"];
  tier: Scalars["String"];
}>;

export type RenewVehicleMutation = {
  __typename?: "Mutation";
  renewVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    slug?: string | null;
    listingTier?: string | null;
    expires?: number | null;
  } | null;
};

export type AddItemToWatchlistMutationVariables = Exact<{
  itemId?: InputMaybe<Scalars["ID"]>;
}>;

export type AddItemToWatchlistMutation = {
  __typename?: "Mutation";
  addItemToWatchlist?: {
    __typename?: "Watchlist";
    _id: string;
    items?: Array<{ __typename?: "Vehicle"; _id: string } | null> | null;
  } | null;
};

export type RemoveItemFromWatchlistMutationVariables = Exact<{
  itemId?: InputMaybe<Scalars["ID"]>;
}>;

export type RemoveItemFromWatchlistMutation = {
  __typename?: "Mutation";
  removeItemFromWatchlist?: {
    __typename?: "Watchlist";
    _id: string;
    items?: Array<{ __typename?: "Vehicle"; _id: string } | null> | null;
  } | null;
};

export type DeleteWatchlistMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteWatchlistMutation = {
  __typename?: "Mutation";
  deleteWatchlist?: { __typename?: "Watchlist"; _id: string } | null;
};

export type GetDiscountQueryVariables = Exact<{
  discountCode: Scalars["String"];
}>;

export type GetDiscountQuery = {
  __typename?: "Query";
  getDiscount?: {
    __typename?: "Discount";
    _id: string;
    discountCode: string;
    percentOff: number;
    userEmails?: Array<string | null> | null;
  } | null;
};

export type GetFavoriteSearchQueryVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type GetFavoriteSearchQuery = {
  __typename?: "Query";
  getFavoriteSearch?: {
    __typename?: "FavoriteSearch";
    _id: string;
    lastVisited?: string | null;
  } | null;
};

export type GetUserFavoriteSearchesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserFavoriteSearchesQuery = {
  __typename?: "Query";
  getUserFavoriteSearches?: Array<{
    __typename?: "FavoriteSearch";
    _id: string;
    query: string;
    lastVisited?: string | null;
    notify?: boolean | null;
    filters: {
      __typename?: "VehicleFiltersType";
      search?: Array<string | null> | null;
      category?: Array<string | null> | null;
      condition?: Array<string | null> | null;
      fuel?: Array<string | null> | null;
      transmission?: Array<string | null> | null;
      drivetrain?: Array<string | null> | null;
      brand?: Array<string | null> | null;
      province?: Array<string | null> | null;
      yearMin?: number | null;
      yearMax?: number | null;
    };
  } | null> | null;
};

export type GetFavoriteSearchesByVehicleQueryVariables = Exact<{
  vehicle?: InputMaybe<VehicleInput>;
}>;

export type GetFavoriteSearchesByVehicleQuery = {
  __typename?: "Query";
  getFavoriteSearchesByVehicle?: Array<{
    __typename?: "FavoriteSearch";
    _id: string;
    userId?: string | null;
    query: string;
    lastVisited?: string | null;
    notify?: boolean | null;
    filters: {
      __typename?: "VehicleFiltersType";
      search?: Array<string | null> | null;
      category?: Array<string | null> | null;
      condition?: Array<string | null> | null;
      fuel?: Array<string | null> | null;
      transmission?: Array<string | null> | null;
      drivetrain?: Array<string | null> | null;
      brand?: Array<string | null> | null;
      province?: Array<string | null> | null;
      yearMin?: number | null;
      yearMax?: number | null;
    };
  } | null> | null;
};

export type GetFinancingQueryVariables = Exact<{
  vehicleId?: InputMaybe<Scalars["String"]>;
  brand?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
}>;

export type GetFinancingQuery = {
  __typename?: "Query";
  getFinancing?: {
    __typename?: "Financing";
    _id: string;
    brand: string;
    model: string;
    downPayment: number;
    term: number;
    years: Array<number>;
  } | null;
};

export type GetFinancingsQueryVariables = Exact<{
  brand?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["Int"]>;
}>;

export type GetFinancingsQuery = {
  __typename?: "Query";
  getFinancings?: Array<{
    __typename?: "Financing";
    _id: string;
    brand: string;
    model: string;
    downPayment: number;
    term: number;
    years: Array<number>;
  } | null> | null;
};

export type GetModelYearlyAvgQueryVariables = Exact<{
  brand: Scalars["String"];
  model: Scalars["String"];
}>;

export type GetModelYearlyAvgQuery = {
  __typename?: "Query";
  getModelYearlyAvg?: {
    __typename?: "ModelYearlyAvgResponse";
    results?: Array<{
      __typename?: "ModelYearlyAvgMeta";
      x?: number | null;
      y?: number | null;
    } | null> | null;
  } | null;
};

export type GetModelsAutocompleteQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  brand: Scalars["String"];
}>;

export type GetModelsAutocompleteQuery = {
  __typename?: "Query";
  getModelsAutocomplete?: Array<{
    __typename?: "HistoricPrice";
    _id: string;
    model: string;
  } | null> | null;
};

export type GetModelAvgPriceQueryVariables = Exact<{
  title?: InputMaybe<Scalars["String"]>;
  brand: Scalars["String"];
  model: Scalars["String"];
  year: Scalars["Int"];
}>;

export type GetModelAvgPriceQuery = {
  __typename?: "Query";
  getModelAvgPrice?: {
    __typename?: "ModelAvgPriceResponse";
    avgPriceCRC?: number | null;
    minPriceCRC?: number | null;
    maxPriceCRC?: number | null;
    avgPriceUSD?: number | null;
    minPriceUSD?: number | null;
    maxPriceUSD?: number | null;
    category?: string | null;
  } | null;
};

export type GetUserLastVisitedQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserLastVisitedQuery = {
  __typename?: "Query";
  getUserLastVisited?: Array<{
    __typename?: "Vehicle";
    _id: string;
    pictures?: Array<string | null> | null;
    thumbs?: Array<string | null> | null;
    brand?: string | null;
    model?: string | null;
    price?: number | null;
    hidePrice?: boolean | null;
    currency?: string | null;
    year?: number | null;
    category?: string | null;
    mileageUnit?: string | null;
    mileage?: number | null;
    fuel?: string | null;
    transmission?: string | null;
    drivetrain?: string | null;
    condition?: string | null;
    receivesAnotherVehicle?: boolean | null;
    negotiable?: boolean | null;
    tags?: string | null;
    listingTier?: string | null;
    createdDate?: number | null;
    expires?: number | null;
    youtubeLink?: string | null;
    isFeatured?: boolean | null;
    slug?: string | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      username?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      image?: string | null;
      idVerified?: boolean | null;
    } | null;
    location?: {
      __typename?: "Location";
      country?: string | null;
      province?: string | null;
      municipality?: string | null;
    } | null;
    financingData?: {
      __typename?: "FinancingData";
      entities?: Array<string | null> | null;
    } | null;
  } | null> | null;
};

export type GetLastVisitedsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
}>;

export type GetLastVisitedsQuery = {
  __typename?: "Query";
  getLastVisiteds?: Array<{
    __typename?: "LastVisited";
    _id: string;
  } | null> | null;
};

export type GetPurchaseQueryVariables = Exact<{
  _id: Scalars["ID"];
}>;

export type GetPurchaseQuery = {
  __typename?: "Query";
  getPurchase?: {
    __typename?: "Purchase";
    _id: string;
    referenceId?: string | null;
    userId: string;
    resourceId?: string | null;
    type: PurchaseTypeEnum;
    pricePayed: number;
    discountApplied?: number | null;
  } | null;
};

export type GetAllPurchasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPurchasesQuery = {
  __typename?: "Query";
  getAllPurchases?: Array<{
    __typename?: "Purchase";
    _id: string;
    referenceId?: string | null;
    userId: string;
    resourceId?: string | null;
    type: PurchaseTypeEnum;
    pricePayed: number;
    discountApplied?: number | null;
  } | null> | null;
};

export type GetUserPurchasesQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserPurchasesQuery = {
  __typename?: "Query";
  getUserPurchases?: Array<{
    __typename?: "Purchase";
    _id: string;
    referenceId?: string | null;
    userId: string;
    resourceId?: string | null;
    type: PurchaseTypeEnum;
    pricePayed: number;
    discountApplied?: number | null;
    dateApplied?: string | null;
    createdDate?: string | null;
    isRenew?: boolean | null;
    resource?: {
      __typename?: "Vehicle";
      _id: string;
      brand?: string | null;
      model?: string | null;
      year?: number | null;
      thumbs?: Array<string | null> | null;
    } | null;
  } | null> | null;
};

export type GetUserQueryVariables = Exact<{
  _id?: InputMaybe<Scalars["ID"]>;
  email?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
}>;

export type GetUserQuery = {
  __typename?: "Query";
  getUser?: {
    __typename?: "User";
    _id?: string | null;
    username?: string | null;
    email?: string | null;
    idVerified?: boolean | null;
    idNumber?: string | null;
    displayName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    secondLastName?: string | null;
    primaryPhone?: string | null;
    secondaryPhone?: string | null;
    image?: string | null;
    company?: {
      __typename?: "Company";
      name?: string | null;
      legalName?: string | null;
      companyId?: string | null;
      about?: string | null;
      website?: string | null;
      logo?: string | null;
      banner?: string | null;
      location?: {
        __typename?: "Location";
        province?: string | null;
        municipality?: string | null;
        address?: string | null;
      } | null;
    } | null;
    subscription?: { __typename?: "Subscription"; type?: string | null } | null;
  } | null;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  getAllUsers?: Array<{
    __typename?: "User";
    _id?: string | null;
    username?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    image?: string | null;
  } | null> | null;
};

export type GetConnectReferrerInfoQueryVariables = Exact<{
  username?: InputMaybe<Scalars["String"]>;
}>;

export type GetConnectReferrerInfoQuery = {
  __typename?: "Query";
  getConnectReferrerInfo?: {
    __typename?: "User";
    _id?: string | null;
    username?: string | null;
    email?: string | null;
    idVerified?: boolean | null;
    displayName?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    secondLastName?: string | null;
    primaryPhone?: string | null;
    secondaryPhone?: string | null;
    image?: string | null;
    company?: {
      __typename?: "Company";
      name?: string | null;
      legalName?: string | null;
      companyId?: string | null;
      about?: string | null;
      website?: string | null;
      logo?: string | null;
      banner?: string | null;
      location?: {
        __typename?: "Location";
        province?: string | null;
        municipality?: string | null;
        address?: string | null;
      } | null;
    } | null;
    subscription?: { __typename?: "Subscription"; type?: string | null } | null;
  } | null;
};

export type GetFeaturedVehiclesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeaturedVehiclesQuery = {
  __typename?: "Query";
  getFeaturedVehicles?: {
    __typename?: "Vehicle";
    _id: string;
    pictures?: Array<string | null> | null;
    thumbs?: Array<string | null> | null;
    brand?: string | null;
    model?: string | null;
    price?: number | null;
    hidePrice?: boolean | null;
    currency?: string | null;
    year?: number | null;
    category?: string | null;
    mileageUnit?: string | null;
    mileage?: number | null;
    fuel?: string | null;
    transmission?: string | null;
    drivetrain?: string | null;
    condition?: string | null;
    receivesAnotherVehicle?: boolean | null;
    negotiable?: boolean | null;
    tags?: string | null;
    listingTier?: string | null;
    createdDate?: number | null;
    expires?: number | null;
    youtubeLink?: string | null;
    isFeatured?: boolean | null;
    slug?: string | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      username?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      image?: string | null;
      idVerified?: boolean | null;
    } | null;
    location?: {
      __typename?: "Location";
      country?: string | null;
      province?: string | null;
      municipality?: string | null;
    } | null;
    financingData?: {
      __typename?: "FinancingData";
      entities?: Array<string | null> | null;
    } | null;
  } | null;
};

export type GetVehicleQueryVariables = Exact<{
  _id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
}>;

export type GetVehicleQuery = {
  __typename?: "Query";
  getVehicle?: {
    __typename?: "Vehicle";
    _id: string;
    brand?: string | null;
    pictures?: Array<string | null> | null;
    thumbs?: Array<string | null> | null;
    model?: string | null;
    price?: number | null;
    hidePrice?: boolean | null;
    currency?: string | null;
    year?: number | null;
    category?: string | null;
    plate?: string | null;
    mileageUnit?: string | null;
    mileage?: number | null;
    fuel?: string | null;
    transmission?: string | null;
    drivetrain?: string | null;
    condition?: string | null;
    description?: string | null;
    receivesAnotherVehicle?: boolean | null;
    negotiable?: boolean | null;
    rtv?: boolean | null;
    permit?: boolean | null;
    cc?: number | null;
    kwh?: number | null;
    soh?: number | null;
    cylinders?: number | null;
    range?: number | null;
    rangeMileageUnit?: string | null;
    equipment?: Array<string | null> | null;
    createdDate?: number | null;
    tags?: string | null;
    bumpCount?: number | null;
    listingTier?: string | null;
    expires?: number | null;
    renewedDate?: number | null;
    previousTier?: string | null;
    isContactInfoPublic?: boolean | null;
    modelVersion?: string | null;
    priceIncludesImportTaxes?: boolean | null;
    priceIncludesTransfer?: boolean | null;
    includesFinancing?: boolean | null;
    guarantee?: boolean | null;
    guaranteeDescription?: string | null;
    platePending?: boolean | null;
    youtubeLink?: string | null;
    slug?: string | null;
    minPrice?: number | null;
    totalFee?: number | null;
    sharedFee?: number | null;
    negotiationStatus?: string | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      displayName?: string | null;
      username?: string | null;
      image?: string | null;
      idVerified?: boolean | null;
      primaryPhone?: string | null;
      subscription?: {
        __typename?: "Subscription";
        type?: string | null;
        status?: string | null;
      } | null;
      company?: {
        __typename?: "Company";
        name?: string | null;
        logo?: string | null;
      } | null;
    } | null;
    color?: {
      __typename?: "Color";
      interior?: string | null;
      exterior?: string | null;
    } | null;
    location?: {
      __typename?: "Location";
      country?: string | null;
      province?: string | null;
      municipality?: string | null;
    } | null;
    financingData?: {
      __typename?: "FinancingData";
      entities?: Array<string | null> | null;
    } | null;
  } | null;
};

export type GetVehiclesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars["String"]>;
  filters?: InputMaybe<VehicleFilters>;
  page?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Sort>;
  random?: InputMaybe<Scalars["Boolean"]>;
  userSubscriptionTypes?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  connect?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetVehiclesQuery = {
  __typename?: "Query";
  getVehicles?: {
    __typename?: "SearchVehiclesResponse";
    meta?: {
      __typename?: "SearchVehiclesMeta";
      total?: number | null;
      pageSize?: number | null;
    } | null;
    data?: Array<{
      __typename?: "Vehicle";
      minPrice?: number | null;
      totalFee?: number | null;
      sharedFee?: number | null;
      negotiationStatus?: string | null;
      _id: string;
      pictures?: Array<string | null> | null;
      thumbs?: Array<string | null> | null;
      brand?: string | null;
      model?: string | null;
      price?: number | null;
      hidePrice?: boolean | null;
      currency?: string | null;
      year?: number | null;
      category?: string | null;
      mileageUnit?: string | null;
      mileage?: number | null;
      fuel?: string | null;
      transmission?: string | null;
      drivetrain?: string | null;
      condition?: string | null;
      receivesAnotherVehicle?: boolean | null;
      negotiable?: boolean | null;
      tags?: string | null;
      listingTier?: string | null;
      createdDate?: number | null;
      expires?: number | null;
      youtubeLink?: string | null;
      isFeatured?: boolean | null;
      slug?: string | null;
      user?: {
        __typename?: "User";
        _id?: string | null;
        username?: string | null;
        displayName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        image?: string | null;
        idVerified?: boolean | null;
      } | null;
      location?: {
        __typename?: "Location";
        country?: string | null;
        province?: string | null;
        municipality?: string | null;
      } | null;
      financingData?: {
        __typename?: "FinancingData";
        entities?: Array<string | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type GetSimilarVehiclesQueryVariables = Exact<{
  _id: Scalars["ID"];
  filters?: InputMaybe<VehicleFilters>;
}>;

export type GetSimilarVehiclesQuery = {
  __typename?: "Query";
  getSimilarVehicles?: Array<{
    __typename?: "Vehicle";
    _id: string;
    pictures?: Array<string | null> | null;
    thumbs?: Array<string | null> | null;
    brand?: string | null;
    model?: string | null;
    price?: number | null;
    hidePrice?: boolean | null;
    currency?: string | null;
    year?: number | null;
    category?: string | null;
    mileageUnit?: string | null;
    mileage?: number | null;
    fuel?: string | null;
    transmission?: string | null;
    drivetrain?: string | null;
    condition?: string | null;
    receivesAnotherVehicle?: boolean | null;
    negotiable?: boolean | null;
    tags?: string | null;
    listingTier?: string | null;
    createdDate?: number | null;
    expires?: number | null;
    youtubeLink?: string | null;
    isFeatured?: boolean | null;
    slug?: string | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      username?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      image?: string | null;
      idVerified?: boolean | null;
    } | null;
    location?: {
      __typename?: "Location";
      country?: string | null;
      province?: string | null;
      municipality?: string | null;
    } | null;
    financingData?: {
      __typename?: "FinancingData";
      entities?: Array<string | null> | null;
    } | null;
  } | null> | null;
};

export type GetUserWatchlistQueryVariables = Exact<{
  connect?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetUserWatchlistQuery = {
  __typename?: "Query";
  getUserWatchlist?: Array<{
    __typename?: "Vehicle";
    minPrice?: number | null;
    totalFee?: number | null;
    sharedFee?: number | null;
    negotiationStatus?: string | null;
    _id: string;
    pictures?: Array<string | null> | null;
    thumbs?: Array<string | null> | null;
    brand?: string | null;
    model?: string | null;
    price?: number | null;
    hidePrice?: boolean | null;
    currency?: string | null;
    year?: number | null;
    category?: string | null;
    mileageUnit?: string | null;
    mileage?: number | null;
    fuel?: string | null;
    transmission?: string | null;
    drivetrain?: string | null;
    condition?: string | null;
    receivesAnotherVehicle?: boolean | null;
    negotiable?: boolean | null;
    tags?: string | null;
    listingTier?: string | null;
    createdDate?: number | null;
    expires?: number | null;
    youtubeLink?: string | null;
    isFeatured?: boolean | null;
    slug?: string | null;
    user?: {
      __typename?: "User";
      _id?: string | null;
      username?: string | null;
      displayName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      image?: string | null;
      idVerified?: boolean | null;
    } | null;
    location?: {
      __typename?: "Location";
      country?: string | null;
      province?: string | null;
      municipality?: string | null;
    } | null;
    financingData?: {
      __typename?: "FinancingData";
      entities?: Array<string | null> | null;
    } | null;
  } | null> | null;
};

export type GetWatchlistsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
}>;

export type GetWatchlistsQuery = {
  __typename?: "Query";
  getWatchlists?: Array<{
    __typename?: "Watchlist";
    _id: string;
  } | null> | null;
};

export const VehicleListDataFragmentDoc = gql`
  fragment VehicleListData on Vehicle {
    _id
    user {
      _id
      username
      displayName
      firstName
      lastName
      image
      idVerified
    }
    pictures
    thumbs
    brand
    model
    price
    hidePrice
    currency
    year
    category
    mileageUnit
    mileage
    fuel
    transmission
    drivetrain
    location {
      country
      province
      municipality
    }
    condition
    receivesAnotherVehicle
    negotiable
    tags
    listingTier
    createdDate
    expires
    youtubeLink
    isFeatured
    slug
    financingData {
      entities
    }
  }
`;
export const AddFavoriteSearchDocument = gql`
  mutation addFavoriteSearch($query: String!, $notify: Boolean) {
    addFavoriteSearch(query: $query, notify: $notify) {
      _id
      query
      notify
    }
  }
`;
export type AddFavoriteSearchMutationFn = Apollo.MutationFunction<
  AddFavoriteSearchMutation,
  AddFavoriteSearchMutationVariables
>;

/**
 * __useAddFavoriteSearchMutation__
 *
 * To run a mutation, you first call `useAddFavoriteSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteSearchMutation, { data, loading, error }] = useAddFavoriteSearchMutation({
 *   variables: {
 *      query: // value for 'query'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useAddFavoriteSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavoriteSearchMutation,
    AddFavoriteSearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddFavoriteSearchMutation,
    AddFavoriteSearchMutationVariables
  >(AddFavoriteSearchDocument, options);
}
export type AddFavoriteSearchMutationHookResult = ReturnType<
  typeof useAddFavoriteSearchMutation
>;
export type AddFavoriteSearchMutationResult =
  Apollo.MutationResult<AddFavoriteSearchMutation>;
export type AddFavoriteSearchMutationOptions = Apollo.BaseMutationOptions<
  AddFavoriteSearchMutation,
  AddFavoriteSearchMutationVariables
>;
export const DeleteFavoriteSearchDocument = gql`
  mutation deleteFavoriteSearch($_id: ID!) {
    deleteFavoriteSearch(_id: $_id) {
      _id
    }
  }
`;
export type DeleteFavoriteSearchMutationFn = Apollo.MutationFunction<
  DeleteFavoriteSearchMutation,
  DeleteFavoriteSearchMutationVariables
>;

/**
 * __useDeleteFavoriteSearchMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteSearchMutation, { data, loading, error }] = useDeleteFavoriteSearchMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeleteFavoriteSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFavoriteSearchMutation,
    DeleteFavoriteSearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFavoriteSearchMutation,
    DeleteFavoriteSearchMutationVariables
  >(DeleteFavoriteSearchDocument, options);
}
export type DeleteFavoriteSearchMutationHookResult = ReturnType<
  typeof useDeleteFavoriteSearchMutation
>;
export type DeleteFavoriteSearchMutationResult =
  Apollo.MutationResult<DeleteFavoriteSearchMutation>;
export type DeleteFavoriteSearchMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoriteSearchMutation,
  DeleteFavoriteSearchMutationVariables
>;
export const UpdateFavoriteSearchDocument = gql`
  mutation updateFavoriteSearch($_id: ID!, $notify: Boolean) {
    updateFavoriteSearch(_id: $_id, notify: $notify) {
      _id
      query
      notify
    }
  }
`;
export type UpdateFavoriteSearchMutationFn = Apollo.MutationFunction<
  UpdateFavoriteSearchMutation,
  UpdateFavoriteSearchMutationVariables
>;

/**
 * __useUpdateFavoriteSearchMutation__
 *
 * To run a mutation, you first call `useUpdateFavoriteSearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFavoriteSearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFavoriteSearchMutation, { data, loading, error }] = useUpdateFavoriteSearchMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useUpdateFavoriteSearchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFavoriteSearchMutation,
    UpdateFavoriteSearchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFavoriteSearchMutation,
    UpdateFavoriteSearchMutationVariables
  >(UpdateFavoriteSearchDocument, options);
}
export type UpdateFavoriteSearchMutationHookResult = ReturnType<
  typeof useUpdateFavoriteSearchMutation
>;
export type UpdateFavoriteSearchMutationResult =
  Apollo.MutationResult<UpdateFavoriteSearchMutation>;
export type UpdateFavoriteSearchMutationOptions = Apollo.BaseMutationOptions<
  UpdateFavoriteSearchMutation,
  UpdateFavoriteSearchMutationVariables
>;
export const DeleteAllFavoriteSearchesDocument = gql`
  mutation deleteAllFavoriteSearches {
    deleteAllFavoriteSearches
  }
`;
export type DeleteAllFavoriteSearchesMutationFn = Apollo.MutationFunction<
  DeleteAllFavoriteSearchesMutation,
  DeleteAllFavoriteSearchesMutationVariables
>;

/**
 * __useDeleteAllFavoriteSearchesMutation__
 *
 * To run a mutation, you first call `useDeleteAllFavoriteSearchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllFavoriteSearchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllFavoriteSearchesMutation, { data, loading, error }] = useDeleteAllFavoriteSearchesMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllFavoriteSearchesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllFavoriteSearchesMutation,
    DeleteAllFavoriteSearchesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAllFavoriteSearchesMutation,
    DeleteAllFavoriteSearchesMutationVariables
  >(DeleteAllFavoriteSearchesDocument, options);
}
export type DeleteAllFavoriteSearchesMutationHookResult = ReturnType<
  typeof useDeleteAllFavoriteSearchesMutation
>;
export type DeleteAllFavoriteSearchesMutationResult =
  Apollo.MutationResult<DeleteAllFavoriteSearchesMutation>;
export type DeleteAllFavoriteSearchesMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteAllFavoriteSearchesMutation,
    DeleteAllFavoriteSearchesMutationVariables
  >;
export const UpdateLastVisitedDocument = gql`
  mutation updateLastVisited($items: [ID]!) {
    updateLastVisited(items: $items) {
      _id
      items {
        _id
      }
    }
  }
`;
export type UpdateLastVisitedMutationFn = Apollo.MutationFunction<
  UpdateLastVisitedMutation,
  UpdateLastVisitedMutationVariables
>;

/**
 * __useUpdateLastVisitedMutation__
 *
 * To run a mutation, you first call `useUpdateLastVisitedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastVisitedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastVisitedMutation, { data, loading, error }] = useUpdateLastVisitedMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateLastVisitedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLastVisitedMutation,
    UpdateLastVisitedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLastVisitedMutation,
    UpdateLastVisitedMutationVariables
  >(UpdateLastVisitedDocument, options);
}
export type UpdateLastVisitedMutationHookResult = ReturnType<
  typeof useUpdateLastVisitedMutation
>;
export type UpdateLastVisitedMutationResult =
  Apollo.MutationResult<UpdateLastVisitedMutation>;
export type UpdateLastVisitedMutationOptions = Apollo.BaseMutationOptions<
  UpdateLastVisitedMutation,
  UpdateLastVisitedMutationVariables
>;
export const RemoveItemFromLastVisitedDocument = gql`
  mutation removeItemFromLastVisited($itemId: ID!) {
    removeItemFromLastVisited(itemId: $itemId) {
      _id
      items {
        _id
      }
    }
  }
`;
export type RemoveItemFromLastVisitedMutationFn = Apollo.MutationFunction<
  RemoveItemFromLastVisitedMutation,
  RemoveItemFromLastVisitedMutationVariables
>;

/**
 * __useRemoveItemFromLastVisitedMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromLastVisitedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromLastVisitedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromLastVisitedMutation, { data, loading, error }] = useRemoveItemFromLastVisitedMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveItemFromLastVisitedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemFromLastVisitedMutation,
    RemoveItemFromLastVisitedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemFromLastVisitedMutation,
    RemoveItemFromLastVisitedMutationVariables
  >(RemoveItemFromLastVisitedDocument, options);
}
export type RemoveItemFromLastVisitedMutationHookResult = ReturnType<
  typeof useRemoveItemFromLastVisitedMutation
>;
export type RemoveItemFromLastVisitedMutationResult =
  Apollo.MutationResult<RemoveItemFromLastVisitedMutation>;
export type RemoveItemFromLastVisitedMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveItemFromLastVisitedMutation,
    RemoveItemFromLastVisitedMutationVariables
  >;
export const DeleteLastVisitedDocument = gql`
  mutation deleteLastVisited {
    deleteLastVisited {
      _id
    }
  }
`;
export type DeleteLastVisitedMutationFn = Apollo.MutationFunction<
  DeleteLastVisitedMutation,
  DeleteLastVisitedMutationVariables
>;

/**
 * __useDeleteLastVisitedMutation__
 *
 * To run a mutation, you first call `useDeleteLastVisitedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLastVisitedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLastVisitedMutation, { data, loading, error }] = useDeleteLastVisitedMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteLastVisitedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLastVisitedMutation,
    DeleteLastVisitedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLastVisitedMutation,
    DeleteLastVisitedMutationVariables
  >(DeleteLastVisitedDocument, options);
}
export type DeleteLastVisitedMutationHookResult = ReturnType<
  typeof useDeleteLastVisitedMutation
>;
export type DeleteLastVisitedMutationResult =
  Apollo.MutationResult<DeleteLastVisitedMutation>;
export type DeleteLastVisitedMutationOptions = Apollo.BaseMutationOptions<
  DeleteLastVisitedMutation,
  DeleteLastVisitedMutationVariables
>;
export const AddPurchaseDocument = gql`
  mutation addPurchase(
    $referenceId: String!
    $userId: String!
    $resourceId: String
    $type: PurchaseTypeEnum!
    $quantity: Int!
    $isRenew: Boolean
    $renewType: String
    $pricePayed: Float!
    $discountApplied: Float
    $discountCodeApplied: String
  ) {
    addPurchase(
      referenceId: $referenceId
      userId: $userId
      resourceId: $resourceId
      type: $type
      quantity: $quantity
      isRenew: $isRenew
      renewType: $renewType
      pricePayed: $pricePayed
      discountApplied: $discountApplied
      discountCodeApplied: $discountCodeApplied
    )
  }
`;
export type AddPurchaseMutationFn = Apollo.MutationFunction<
  AddPurchaseMutation,
  AddPurchaseMutationVariables
>;

/**
 * __useAddPurchaseMutation__
 *
 * To run a mutation, you first call `useAddPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPurchaseMutation, { data, loading, error }] = useAddPurchaseMutation({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *      userId: // value for 'userId'
 *      resourceId: // value for 'resourceId'
 *      type: // value for 'type'
 *      quantity: // value for 'quantity'
 *      isRenew: // value for 'isRenew'
 *      renewType: // value for 'renewType'
 *      pricePayed: // value for 'pricePayed'
 *      discountApplied: // value for 'discountApplied'
 *      discountCodeApplied: // value for 'discountCodeApplied'
 *   },
 * });
 */
export function useAddPurchaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPurchaseMutation,
    AddPurchaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPurchaseMutation, AddPurchaseMutationVariables>(
    AddPurchaseDocument,
    options
  );
}
export type AddPurchaseMutationHookResult = ReturnType<
  typeof useAddPurchaseMutation
>;
export type AddPurchaseMutationResult =
  Apollo.MutationResult<AddPurchaseMutation>;
export type AddPurchaseMutationOptions = Apollo.BaseMutationOptions<
  AddPurchaseMutation,
  AddPurchaseMutationVariables
>;
export const ApplyPurchaseDocument = gql`
  mutation applyPurchase($_id: ID!, $resourceId: ID!) {
    applyPurchase(_id: $_id, resourceId: $resourceId) {
      _id
      resourceId
    }
  }
`;
export type ApplyPurchaseMutationFn = Apollo.MutationFunction<
  ApplyPurchaseMutation,
  ApplyPurchaseMutationVariables
>;

/**
 * __useApplyPurchaseMutation__
 *
 * To run a mutation, you first call `useApplyPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPurchaseMutation, { data, loading, error }] = useApplyPurchaseMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useApplyPurchaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyPurchaseMutation,
    ApplyPurchaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApplyPurchaseMutation,
    ApplyPurchaseMutationVariables
  >(ApplyPurchaseDocument, options);
}
export type ApplyPurchaseMutationHookResult = ReturnType<
  typeof useApplyPurchaseMutation
>;
export type ApplyPurchaseMutationResult =
  Apollo.MutationResult<ApplyPurchaseMutation>;
export type ApplyPurchaseMutationOptions = Apollo.BaseMutationOptions<
  ApplyPurchaseMutation,
  ApplyPurchaseMutationVariables
>;
export const DeletePurchaseDocument = gql`
  mutation deletePurchase($_id: ID!) {
    deletePurchase(_id: $_id) {
      _id
    }
  }
`;
export type DeletePurchaseMutationFn = Apollo.MutationFunction<
  DeletePurchaseMutation,
  DeletePurchaseMutationVariables
>;

/**
 * __useDeletePurchaseMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchaseMutation, { data, loading, error }] = useDeletePurchaseMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeletePurchaseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePurchaseMutation,
    DeletePurchaseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePurchaseMutation,
    DeletePurchaseMutationVariables
  >(DeletePurchaseDocument, options);
}
export type DeletePurchaseMutationHookResult = ReturnType<
  typeof useDeletePurchaseMutation
>;
export type DeletePurchaseMutationResult =
  Apollo.MutationResult<DeletePurchaseMutation>;
export type DeletePurchaseMutationOptions = Apollo.BaseMutationOptions<
  DeletePurchaseMutation,
  DeletePurchaseMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      _id
      username
      email
      idVerified
      firstName
      lastName
      secondLastName
      primaryPhone
      secondaryPhone
      image
      company {
        name
        legalName
        companyId
        about
        logo
        location {
          province
          municipality
          address
        }
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const VerifyUserIdDocument = gql`
  mutation verifyUserId($_id: ID!, $idUrl: String, $faceWithIdUrl: String) {
    verifyUserId(_id: $_id, idUrl: $idUrl, faceWithIdUrl: $faceWithIdUrl) {
      _id
      idVerified
      idNumber
      firstName
      lastName
      secondLastName
    }
  }
`;
export type VerifyUserIdMutationFn = Apollo.MutationFunction<
  VerifyUserIdMutation,
  VerifyUserIdMutationVariables
>;

/**
 * __useVerifyUserIdMutation__
 *
 * To run a mutation, you first call `useVerifyUserIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserIdMutation, { data, loading, error }] = useVerifyUserIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      idUrl: // value for 'idUrl'
 *      faceWithIdUrl: // value for 'faceWithIdUrl'
 *   },
 * });
 */
export function useVerifyUserIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyUserIdMutation,
    VerifyUserIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyUserIdMutation,
    VerifyUserIdMutationVariables
  >(VerifyUserIdDocument, options);
}
export type VerifyUserIdMutationHookResult = ReturnType<
  typeof useVerifyUserIdMutation
>;
export type VerifyUserIdMutationResult =
  Apollo.MutationResult<VerifyUserIdMutation>;
export type VerifyUserIdMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserIdMutation,
  VerifyUserIdMutationVariables
>;
export const DeactivateUserAccountDocument = gql`
  mutation deactivateUserAccount($_id: ID!) {
    deactivateUserAccount(_id: $_id) {
      _id
    }
  }
`;
export type DeactivateUserAccountMutationFn = Apollo.MutationFunction<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;

/**
 * __useDeactivateUserAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateUserAccountMutation, { data, loading, error }] = useDeactivateUserAccountMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useDeactivateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateUserAccountMutation,
    DeactivateUserAccountMutationVariables
  >(DeactivateUserAccountDocument, options);
}
export type DeactivateUserAccountMutationHookResult = ReturnType<
  typeof useDeactivateUserAccountMutation
>;
export type DeactivateUserAccountMutationResult =
  Apollo.MutationResult<DeactivateUserAccountMutation>;
export type DeactivateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  DeactivateUserAccountMutation,
  DeactivateUserAccountMutationVariables
>;
export const ReactivateUserAccountDocument = gql`
  mutation reactivateUserAccount($_id: ID!) {
    reactivateUserAccount(_id: $_id) {
      _id
    }
  }
`;
export type ReactivateUserAccountMutationFn = Apollo.MutationFunction<
  ReactivateUserAccountMutation,
  ReactivateUserAccountMutationVariables
>;

/**
 * __useReactivateUserAccountMutation__
 *
 * To run a mutation, you first call `useReactivateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateUserAccountMutation, { data, loading, error }] = useReactivateUserAccountMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useReactivateUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateUserAccountMutation,
    ReactivateUserAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateUserAccountMutation,
    ReactivateUserAccountMutationVariables
  >(ReactivateUserAccountDocument, options);
}
export type ReactivateUserAccountMutationHookResult = ReturnType<
  typeof useReactivateUserAccountMutation
>;
export type ReactivateUserAccountMutationResult =
  Apollo.MutationResult<ReactivateUserAccountMutation>;
export type ReactivateUserAccountMutationOptions = Apollo.BaseMutationOptions<
  ReactivateUserAccountMutation,
  ReactivateUserAccountMutationVariables
>;
export const AcceptTermsAndConditionsDocument = gql`
  mutation acceptTermsAndConditions($_id: ID!, $type: String!) {
    acceptTermsAndConditions(_id: $_id, type: $type) {
      _id
      terms {
        type
        acceptedVersion
      }
    }
  }
`;
export type AcceptTermsAndConditionsMutationFn = Apollo.MutationFunction<
  AcceptTermsAndConditionsMutation,
  AcceptTermsAndConditionsMutationVariables
>;

/**
 * __useAcceptTermsAndConditionsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsAndConditionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsAndConditionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsAndConditionsMutation, { data, loading, error }] = useAcceptTermsAndConditionsMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAcceptTermsAndConditionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >(AcceptTermsAndConditionsDocument, options);
}
export type AcceptTermsAndConditionsMutationHookResult = ReturnType<
  typeof useAcceptTermsAndConditionsMutation
>;
export type AcceptTermsAndConditionsMutationResult =
  Apollo.MutationResult<AcceptTermsAndConditionsMutation>;
export type AcceptTermsAndConditionsMutationOptions =
  Apollo.BaseMutationOptions<
    AcceptTermsAndConditionsMutation,
    AcceptTermsAndConditionsMutationVariables
  >;
export const AddVehicleDocument = gql`
  mutation addVehicle($vehicle: VehicleInput!) {
    addVehicle(vehicle: $vehicle) {
      _id
      slug
      brand
      model
      year
      thumbs
    }
  }
`;
export type AddVehicleMutationFn = Apollo.MutationFunction<
  AddVehicleMutation,
  AddVehicleMutationVariables
>;

/**
 * __useAddVehicleMutation__
 *
 * To run a mutation, you first call `useAddVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleMutation, { data, loading, error }] = useAddVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useAddVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVehicleMutation,
    AddVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddVehicleMutation, AddVehicleMutationVariables>(
    AddVehicleDocument,
    options
  );
}
export type AddVehicleMutationHookResult = ReturnType<
  typeof useAddVehicleMutation
>;
export type AddVehicleMutationResult =
  Apollo.MutationResult<AddVehicleMutation>;
export type AddVehicleMutationOptions = Apollo.BaseMutationOptions<
  AddVehicleMutation,
  AddVehicleMutationVariables
>;
export const UpdateVehicleDocument = gql`
  mutation updateVehicle($vehicle: VehicleInput!) {
    updateVehicle(vehicle: $vehicle) {
      _id
      slug
    }
  }
`;
export type UpdateVehicleMutationFn = Apollo.MutationFunction<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;

/**
 * __useUpdateVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleMutation, { data, loading, error }] = useUpdateVehicleMutation({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useUpdateVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVehicleMutation,
    UpdateVehicleMutationVariables
  >(UpdateVehicleDocument, options);
}
export type UpdateVehicleMutationHookResult = ReturnType<
  typeof useUpdateVehicleMutation
>;
export type UpdateVehicleMutationResult =
  Apollo.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables
>;
export const DeleteVehicleDocument = gql`
  mutation deleteVehicle($_id: ID!, $deletionCause: String!) {
    deleteVehicle(_id: $_id, deletionCause: $deletionCause) {
      _id
      slug
    }
  }
`;
export type DeleteVehicleMutationFn = Apollo.MutationFunction<
  DeleteVehicleMutation,
  DeleteVehicleMutationVariables
>;

/**
 * __useDeleteVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleMutation, { data, loading, error }] = useDeleteVehicleMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      deletionCause: // value for 'deletionCause'
 *   },
 * });
 */
export function useDeleteVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVehicleMutation,
    DeleteVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVehicleMutation,
    DeleteVehicleMutationVariables
  >(DeleteVehicleDocument, options);
}
export type DeleteVehicleMutationHookResult = ReturnType<
  typeof useDeleteVehicleMutation
>;
export type DeleteVehicleMutationResult =
  Apollo.MutationResult<DeleteVehicleMutation>;
export type DeleteVehicleMutationOptions = Apollo.BaseMutationOptions<
  DeleteVehicleMutation,
  DeleteVehicleMutationVariables
>;
export const BumpVehicleDocument = gql`
  mutation bumpVehicle($_id: ID!) {
    bumpVehicle(_id: $_id) {
      _id
      slug
      bumpCount
    }
  }
`;
export type BumpVehicleMutationFn = Apollo.MutationFunction<
  BumpVehicleMutation,
  BumpVehicleMutationVariables
>;

/**
 * __useBumpVehicleMutation__
 *
 * To run a mutation, you first call `useBumpVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBumpVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bumpVehicleMutation, { data, loading, error }] = useBumpVehicleMutation({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useBumpVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BumpVehicleMutation,
    BumpVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BumpVehicleMutation, BumpVehicleMutationVariables>(
    BumpVehicleDocument,
    options
  );
}
export type BumpVehicleMutationHookResult = ReturnType<
  typeof useBumpVehicleMutation
>;
export type BumpVehicleMutationResult =
  Apollo.MutationResult<BumpVehicleMutation>;
export type BumpVehicleMutationOptions = Apollo.BaseMutationOptions<
  BumpVehicleMutation,
  BumpVehicleMutationVariables
>;
export const RenewVehicleDocument = gql`
  mutation renewVehicle($_id: ID!, $tier: String!) {
    renewVehicle(_id: $_id, tier: $tier) {
      _id
      slug
      listingTier
      expires
    }
  }
`;
export type RenewVehicleMutationFn = Apollo.MutationFunction<
  RenewVehicleMutation,
  RenewVehicleMutationVariables
>;

/**
 * __useRenewVehicleMutation__
 *
 * To run a mutation, you first call `useRenewVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewVehicleMutation, { data, loading, error }] = useRenewVehicleMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      tier: // value for 'tier'
 *   },
 * });
 */
export function useRenewVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenewVehicleMutation,
    RenewVehicleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RenewVehicleMutation,
    RenewVehicleMutationVariables
  >(RenewVehicleDocument, options);
}
export type RenewVehicleMutationHookResult = ReturnType<
  typeof useRenewVehicleMutation
>;
export type RenewVehicleMutationResult =
  Apollo.MutationResult<RenewVehicleMutation>;
export type RenewVehicleMutationOptions = Apollo.BaseMutationOptions<
  RenewVehicleMutation,
  RenewVehicleMutationVariables
>;
export const AddItemToWatchlistDocument = gql`
  mutation addItemToWatchlist($itemId: ID) {
    addItemToWatchlist(itemId: $itemId) {
      _id
      items {
        _id
      }
    }
  }
`;
export type AddItemToWatchlistMutationFn = Apollo.MutationFunction<
  AddItemToWatchlistMutation,
  AddItemToWatchlistMutationVariables
>;

/**
 * __useAddItemToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddItemToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddItemToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addItemToWatchlistMutation, { data, loading, error }] = useAddItemToWatchlistMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAddItemToWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddItemToWatchlistMutation,
    AddItemToWatchlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddItemToWatchlistMutation,
    AddItemToWatchlistMutationVariables
  >(AddItemToWatchlistDocument, options);
}
export type AddItemToWatchlistMutationHookResult = ReturnType<
  typeof useAddItemToWatchlistMutation
>;
export type AddItemToWatchlistMutationResult =
  Apollo.MutationResult<AddItemToWatchlistMutation>;
export type AddItemToWatchlistMutationOptions = Apollo.BaseMutationOptions<
  AddItemToWatchlistMutation,
  AddItemToWatchlistMutationVariables
>;
export const RemoveItemFromWatchlistDocument = gql`
  mutation removeItemFromWatchlist($itemId: ID) {
    removeItemFromWatchlist(itemId: $itemId) {
      _id
      items {
        _id
      }
    }
  }
`;
export type RemoveItemFromWatchlistMutationFn = Apollo.MutationFunction<
  RemoveItemFromWatchlistMutation,
  RemoveItemFromWatchlistMutationVariables
>;

/**
 * __useRemoveItemFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveItemFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveItemFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeItemFromWatchlistMutation, { data, loading, error }] = useRemoveItemFromWatchlistMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveItemFromWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveItemFromWatchlistMutation,
    RemoveItemFromWatchlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveItemFromWatchlistMutation,
    RemoveItemFromWatchlistMutationVariables
  >(RemoveItemFromWatchlistDocument, options);
}
export type RemoveItemFromWatchlistMutationHookResult = ReturnType<
  typeof useRemoveItemFromWatchlistMutation
>;
export type RemoveItemFromWatchlistMutationResult =
  Apollo.MutationResult<RemoveItemFromWatchlistMutation>;
export type RemoveItemFromWatchlistMutationOptions = Apollo.BaseMutationOptions<
  RemoveItemFromWatchlistMutation,
  RemoveItemFromWatchlistMutationVariables
>;
export const DeleteWatchlistDocument = gql`
  mutation deleteWatchlist {
    deleteWatchlist {
      _id
    }
  }
`;
export type DeleteWatchlistMutationFn = Apollo.MutationFunction<
  DeleteWatchlistMutation,
  DeleteWatchlistMutationVariables
>;

/**
 * __useDeleteWatchlistMutation__
 *
 * To run a mutation, you first call `useDeleteWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWatchlistMutation, { data, loading, error }] = useDeleteWatchlistMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteWatchlistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWatchlistMutation,
    DeleteWatchlistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWatchlistMutation,
    DeleteWatchlistMutationVariables
  >(DeleteWatchlistDocument, options);
}
export type DeleteWatchlistMutationHookResult = ReturnType<
  typeof useDeleteWatchlistMutation
>;
export type DeleteWatchlistMutationResult =
  Apollo.MutationResult<DeleteWatchlistMutation>;
export type DeleteWatchlistMutationOptions = Apollo.BaseMutationOptions<
  DeleteWatchlistMutation,
  DeleteWatchlistMutationVariables
>;
export const GetDiscountDocument = gql`
  query getDiscount($discountCode: String!) {
    getDiscount(discountCode: $discountCode) {
      _id
      discountCode
      percentOff
      userEmails
    }
  }
`;

/**
 * __useGetDiscountQuery__
 *
 * To run a query within a React component, call `useGetDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountQuery({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useGetDiscountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDiscountQuery,
    GetDiscountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiscountQuery, GetDiscountQueryVariables>(
    GetDiscountDocument,
    options
  );
}
export function useGetDiscountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDiscountQuery,
    GetDiscountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiscountQuery, GetDiscountQueryVariables>(
    GetDiscountDocument,
    options
  );
}
export type GetDiscountQueryHookResult = ReturnType<typeof useGetDiscountQuery>;
export type GetDiscountLazyQueryHookResult = ReturnType<
  typeof useGetDiscountLazyQuery
>;
export type GetDiscountQueryResult = Apollo.QueryResult<
  GetDiscountQuery,
  GetDiscountQueryVariables
>;
export const GetFavoriteSearchDocument = gql`
  query getFavoriteSearch($_id: ID!) {
    getFavoriteSearch(_id: $_id) {
      _id
      lastVisited
    }
  }
`;

/**
 * __useGetFavoriteSearchQuery__
 *
 * To run a query within a React component, call `useGetFavoriteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteSearchQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetFavoriteSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFavoriteSearchQuery,
    GetFavoriteSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFavoriteSearchQuery,
    GetFavoriteSearchQueryVariables
  >(GetFavoriteSearchDocument, options);
}
export function useGetFavoriteSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoriteSearchQuery,
    GetFavoriteSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFavoriteSearchQuery,
    GetFavoriteSearchQueryVariables
  >(GetFavoriteSearchDocument, options);
}
export type GetFavoriteSearchQueryHookResult = ReturnType<
  typeof useGetFavoriteSearchQuery
>;
export type GetFavoriteSearchLazyQueryHookResult = ReturnType<
  typeof useGetFavoriteSearchLazyQuery
>;
export type GetFavoriteSearchQueryResult = Apollo.QueryResult<
  GetFavoriteSearchQuery,
  GetFavoriteSearchQueryVariables
>;
export const GetUserFavoriteSearchesDocument = gql`
  query getUserFavoriteSearches {
    getUserFavoriteSearches {
      _id
      query
      lastVisited
      notify
      filters {
        search
        category
        condition
        fuel
        transmission
        drivetrain
        brand
        province
        yearMin
        yearMax
      }
    }
  }
`;

/**
 * __useGetUserFavoriteSearchesQuery__
 *
 * To run a query within a React component, call `useGetUserFavoriteSearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFavoriteSearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFavoriteSearchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFavoriteSearchesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserFavoriteSearchesQuery,
    GetUserFavoriteSearchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserFavoriteSearchesQuery,
    GetUserFavoriteSearchesQueryVariables
  >(GetUserFavoriteSearchesDocument, options);
}
export function useGetUserFavoriteSearchesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFavoriteSearchesQuery,
    GetUserFavoriteSearchesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserFavoriteSearchesQuery,
    GetUserFavoriteSearchesQueryVariables
  >(GetUserFavoriteSearchesDocument, options);
}
export type GetUserFavoriteSearchesQueryHookResult = ReturnType<
  typeof useGetUserFavoriteSearchesQuery
>;
export type GetUserFavoriteSearchesLazyQueryHookResult = ReturnType<
  typeof useGetUserFavoriteSearchesLazyQuery
>;
export type GetUserFavoriteSearchesQueryResult = Apollo.QueryResult<
  GetUserFavoriteSearchesQuery,
  GetUserFavoriteSearchesQueryVariables
>;
export const GetFavoriteSearchesByVehicleDocument = gql`
  query getFavoriteSearchesByVehicle($vehicle: VehicleInput) {
    getFavoriteSearchesByVehicle(vehicle: $vehicle) {
      _id
      userId
      query
      lastVisited
      notify
      filters {
        search
        category
        condition
        fuel
        transmission
        drivetrain
        brand
        province
        yearMin
        yearMax
      }
    }
  }
`;

/**
 * __useGetFavoriteSearchesByVehicleQuery__
 *
 * To run a query within a React component, call `useGetFavoriteSearchesByVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteSearchesByVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteSearchesByVehicleQuery({
 *   variables: {
 *      vehicle: // value for 'vehicle'
 *   },
 * });
 */
export function useGetFavoriteSearchesByVehicleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFavoriteSearchesByVehicleQuery,
    GetFavoriteSearchesByVehicleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFavoriteSearchesByVehicleQuery,
    GetFavoriteSearchesByVehicleQueryVariables
  >(GetFavoriteSearchesByVehicleDocument, options);
}
export function useGetFavoriteSearchesByVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoriteSearchesByVehicleQuery,
    GetFavoriteSearchesByVehicleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFavoriteSearchesByVehicleQuery,
    GetFavoriteSearchesByVehicleQueryVariables
  >(GetFavoriteSearchesByVehicleDocument, options);
}
export type GetFavoriteSearchesByVehicleQueryHookResult = ReturnType<
  typeof useGetFavoriteSearchesByVehicleQuery
>;
export type GetFavoriteSearchesByVehicleLazyQueryHookResult = ReturnType<
  typeof useGetFavoriteSearchesByVehicleLazyQuery
>;
export type GetFavoriteSearchesByVehicleQueryResult = Apollo.QueryResult<
  GetFavoriteSearchesByVehicleQuery,
  GetFavoriteSearchesByVehicleQueryVariables
>;
export const GetFinancingDocument = gql`
  query getFinancing(
    $vehicleId: String
    $brand: String
    $model: String
    $year: Int
  ) {
    getFinancing(
      vehicleId: $vehicleId
      brand: $brand
      model: $model
      year: $year
    ) {
      _id
      brand
      model
      downPayment
      term
      years
    }
  }
`;

/**
 * __useGetFinancingQuery__
 *
 * To run a query within a React component, call `useGetFinancingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetFinancingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFinancingQuery,
    GetFinancingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFinancingQuery, GetFinancingQueryVariables>(
    GetFinancingDocument,
    options
  );
}
export function useGetFinancingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancingQuery,
    GetFinancingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFinancingQuery, GetFinancingQueryVariables>(
    GetFinancingDocument,
    options
  );
}
export type GetFinancingQueryHookResult = ReturnType<
  typeof useGetFinancingQuery
>;
export type GetFinancingLazyQueryHookResult = ReturnType<
  typeof useGetFinancingLazyQuery
>;
export type GetFinancingQueryResult = Apollo.QueryResult<
  GetFinancingQuery,
  GetFinancingQueryVariables
>;
export const GetFinancingsDocument = gql`
  query getFinancings($brand: String, $model: String, $year: Int) {
    getFinancings(brand: $brand, model: $model, year: $year) {
      _id
      brand
      model
      downPayment
      term
      years
    }
  }
`;

/**
 * __useGetFinancingsQuery__
 *
 * To run a query within a React component, call `useGetFinancingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancingsQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetFinancingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFinancingsQuery,
    GetFinancingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFinancingsQuery, GetFinancingsQueryVariables>(
    GetFinancingsDocument,
    options
  );
}
export function useGetFinancingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFinancingsQuery,
    GetFinancingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFinancingsQuery, GetFinancingsQueryVariables>(
    GetFinancingsDocument,
    options
  );
}
export type GetFinancingsQueryHookResult = ReturnType<
  typeof useGetFinancingsQuery
>;
export type GetFinancingsLazyQueryHookResult = ReturnType<
  typeof useGetFinancingsLazyQuery
>;
export type GetFinancingsQueryResult = Apollo.QueryResult<
  GetFinancingsQuery,
  GetFinancingsQueryVariables
>;
export const GetModelYearlyAvgDocument = gql`
  query getModelYearlyAvg($brand: String!, $model: String!) {
    getModelYearlyAvg(brand: $brand, model: $model) {
      results {
        x
        y
      }
    }
  }
`;

/**
 * __useGetModelYearlyAvgQuery__
 *
 * To run a query within a React component, call `useGetModelYearlyAvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelYearlyAvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelYearlyAvgQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useGetModelYearlyAvgQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModelYearlyAvgQuery,
    GetModelYearlyAvgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModelYearlyAvgQuery,
    GetModelYearlyAvgQueryVariables
  >(GetModelYearlyAvgDocument, options);
}
export function useGetModelYearlyAvgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelYearlyAvgQuery,
    GetModelYearlyAvgQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModelYearlyAvgQuery,
    GetModelYearlyAvgQueryVariables
  >(GetModelYearlyAvgDocument, options);
}
export type GetModelYearlyAvgQueryHookResult = ReturnType<
  typeof useGetModelYearlyAvgQuery
>;
export type GetModelYearlyAvgLazyQueryHookResult = ReturnType<
  typeof useGetModelYearlyAvgLazyQuery
>;
export type GetModelYearlyAvgQueryResult = Apollo.QueryResult<
  GetModelYearlyAvgQuery,
  GetModelYearlyAvgQueryVariables
>;
export const GetModelsAutocompleteDocument = gql`
  query getModelsAutocomplete($query: String, $brand: String!) {
    getModelsAutocomplete(query: $query, brand: $brand) {
      _id
      model
    }
  }
`;

/**
 * __useGetModelsAutocompleteQuery__
 *
 * To run a query within a React component, call `useGetModelsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetModelsAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModelsAutocompleteQuery,
    GetModelsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModelsAutocompleteQuery,
    GetModelsAutocompleteQueryVariables
  >(GetModelsAutocompleteDocument, options);
}
export function useGetModelsAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelsAutocompleteQuery,
    GetModelsAutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModelsAutocompleteQuery,
    GetModelsAutocompleteQueryVariables
  >(GetModelsAutocompleteDocument, options);
}
export type GetModelsAutocompleteQueryHookResult = ReturnType<
  typeof useGetModelsAutocompleteQuery
>;
export type GetModelsAutocompleteLazyQueryHookResult = ReturnType<
  typeof useGetModelsAutocompleteLazyQuery
>;
export type GetModelsAutocompleteQueryResult = Apollo.QueryResult<
  GetModelsAutocompleteQuery,
  GetModelsAutocompleteQueryVariables
>;
export const GetModelAvgPriceDocument = gql`
  query getModelAvgPrice(
    $title: String
    $brand: String!
    $model: String!
    $year: Int!
  ) {
    getModelAvgPrice(title: $title, brand: $brand, model: $model, year: $year) {
      avgPriceCRC
      minPriceCRC
      maxPriceCRC
      avgPriceUSD
      minPriceUSD
      maxPriceUSD
      category
    }
  }
`;

/**
 * __useGetModelAvgPriceQuery__
 *
 * To run a query within a React component, call `useGetModelAvgPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelAvgPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelAvgPriceQuery({
 *   variables: {
 *      title: // value for 'title'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetModelAvgPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModelAvgPriceQuery,
    GetModelAvgPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelAvgPriceQuery, GetModelAvgPriceQueryVariables>(
    GetModelAvgPriceDocument,
    options
  );
}
export function useGetModelAvgPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModelAvgPriceQuery,
    GetModelAvgPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModelAvgPriceQuery,
    GetModelAvgPriceQueryVariables
  >(GetModelAvgPriceDocument, options);
}
export type GetModelAvgPriceQueryHookResult = ReturnType<
  typeof useGetModelAvgPriceQuery
>;
export type GetModelAvgPriceLazyQueryHookResult = ReturnType<
  typeof useGetModelAvgPriceLazyQuery
>;
export type GetModelAvgPriceQueryResult = Apollo.QueryResult<
  GetModelAvgPriceQuery,
  GetModelAvgPriceQueryVariables
>;
export const GetUserLastVisitedDocument = gql`
  query getUserLastVisited {
    getUserLastVisited {
      ...VehicleListData
    }
  }
  ${VehicleListDataFragmentDoc}
`;

/**
 * __useGetUserLastVisitedQuery__
 *
 * To run a query within a React component, call `useGetUserLastVisitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLastVisitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLastVisitedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLastVisitedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserLastVisitedQuery,
    GetUserLastVisitedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserLastVisitedQuery,
    GetUserLastVisitedQueryVariables
  >(GetUserLastVisitedDocument, options);
}
export function useGetUserLastVisitedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserLastVisitedQuery,
    GetUserLastVisitedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserLastVisitedQuery,
    GetUserLastVisitedQueryVariables
  >(GetUserLastVisitedDocument, options);
}
export type GetUserLastVisitedQueryHookResult = ReturnType<
  typeof useGetUserLastVisitedQuery
>;
export type GetUserLastVisitedLazyQueryHookResult = ReturnType<
  typeof useGetUserLastVisitedLazyQuery
>;
export type GetUserLastVisitedQueryResult = Apollo.QueryResult<
  GetUserLastVisitedQuery,
  GetUserLastVisitedQueryVariables
>;
export const GetLastVisitedsDocument = gql`
  query getLastVisiteds($query: String) {
    getLastVisiteds(query: $query) {
      _id
    }
  }
`;

/**
 * __useGetLastVisitedsQuery__
 *
 * To run a query within a React component, call `useGetLastVisitedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastVisitedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastVisitedsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetLastVisitedsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLastVisitedsQuery,
    GetLastVisitedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastVisitedsQuery, GetLastVisitedsQueryVariables>(
    GetLastVisitedsDocument,
    options
  );
}
export function useGetLastVisitedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastVisitedsQuery,
    GetLastVisitedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastVisitedsQuery,
    GetLastVisitedsQueryVariables
  >(GetLastVisitedsDocument, options);
}
export type GetLastVisitedsQueryHookResult = ReturnType<
  typeof useGetLastVisitedsQuery
>;
export type GetLastVisitedsLazyQueryHookResult = ReturnType<
  typeof useGetLastVisitedsLazyQuery
>;
export type GetLastVisitedsQueryResult = Apollo.QueryResult<
  GetLastVisitedsQuery,
  GetLastVisitedsQueryVariables
>;
export const GetPurchaseDocument = gql`
  query getPurchase($_id: ID!) {
    getPurchase(_id: $_id) {
      _id
      referenceId
      userId
      resourceId
      type
      pricePayed
      discountApplied
    }
  }
`;

/**
 * __useGetPurchaseQuery__
 *
 * To run a query within a React component, call `useGetPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useGetPurchaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPurchaseQuery,
    GetPurchaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(
    GetPurchaseDocument,
    options
  );
}
export function useGetPurchaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseQuery,
    GetPurchaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPurchaseQuery, GetPurchaseQueryVariables>(
    GetPurchaseDocument,
    options
  );
}
export type GetPurchaseQueryHookResult = ReturnType<typeof useGetPurchaseQuery>;
export type GetPurchaseLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseLazyQuery
>;
export type GetPurchaseQueryResult = Apollo.QueryResult<
  GetPurchaseQuery,
  GetPurchaseQueryVariables
>;
export const GetAllPurchasesDocument = gql`
  query getAllPurchases {
    getAllPurchases {
      _id
      referenceId
      userId
      resourceId
      type
      pricePayed
      discountApplied
    }
  }
`;

/**
 * __useGetAllPurchasesQuery__
 *
 * To run a query within a React component, call `useGetAllPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPurchasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPurchasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPurchasesQuery,
    GetAllPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPurchasesQuery, GetAllPurchasesQueryVariables>(
    GetAllPurchasesDocument,
    options
  );
}
export function useGetAllPurchasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPurchasesQuery,
    GetAllPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPurchasesQuery,
    GetAllPurchasesQueryVariables
  >(GetAllPurchasesDocument, options);
}
export type GetAllPurchasesQueryHookResult = ReturnType<
  typeof useGetAllPurchasesQuery
>;
export type GetAllPurchasesLazyQueryHookResult = ReturnType<
  typeof useGetAllPurchasesLazyQuery
>;
export type GetAllPurchasesQueryResult = Apollo.QueryResult<
  GetAllPurchasesQuery,
  GetAllPurchasesQueryVariables
>;
export const GetUserPurchasesDocument = gql`
  query getUserPurchases {
    getUserPurchases {
      _id
      referenceId
      userId
      resourceId
      type
      pricePayed
      discountApplied
      dateApplied
      createdDate
      resource {
        _id
        brand
        model
        year
        thumbs
      }
      isRenew
    }
  }
`;

/**
 * __useGetUserPurchasesQuery__
 *
 * To run a query within a React component, call `useGetUserPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPurchasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPurchasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserPurchasesQuery,
    GetUserPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserPurchasesQuery, GetUserPurchasesQueryVariables>(
    GetUserPurchasesDocument,
    options
  );
}
export function useGetUserPurchasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserPurchasesQuery,
    GetUserPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserPurchasesQuery,
    GetUserPurchasesQueryVariables
  >(GetUserPurchasesDocument, options);
}
export type GetUserPurchasesQueryHookResult = ReturnType<
  typeof useGetUserPurchasesQuery
>;
export type GetUserPurchasesLazyQueryHookResult = ReturnType<
  typeof useGetUserPurchasesLazyQuery
>;
export type GetUserPurchasesQueryResult = Apollo.QueryResult<
  GetUserPurchasesQuery,
  GetUserPurchasesQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($_id: ID, $email: String, $username: String) {
    getUser(_id: $_id, email: $email, username: $username) {
      _id
      username
      email
      idVerified
      idNumber
      displayName
      firstName
      lastName
      secondLastName
      primaryPhone
      secondaryPhone
      image
      company {
        name
        legalName
        companyId
        about
        website
        logo
        banner
        location {
          province
          municipality
          address
        }
      }
      subscription {
        type
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetAllUsersDocument = gql`
  query getAllUsers {
    getAllUsers {
      _id
      username
      email
      firstName
      lastName
      image
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const GetConnectReferrerInfoDocument = gql`
  query getConnectReferrerInfo($username: String) {
    getConnectReferrerInfo(username: $username) {
      _id
      username
      email
      idVerified
      displayName
      firstName
      lastName
      secondLastName
      primaryPhone
      secondaryPhone
      image
      company {
        name
        legalName
        companyId
        about
        website
        logo
        banner
        location {
          province
          municipality
          address
        }
      }
      subscription {
        type
      }
    }
  }
`;

/**
 * __useGetConnectReferrerInfoQuery__
 *
 * To run a query within a React component, call `useGetConnectReferrerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectReferrerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectReferrerInfoQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetConnectReferrerInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetConnectReferrerInfoQuery,
    GetConnectReferrerInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetConnectReferrerInfoQuery,
    GetConnectReferrerInfoQueryVariables
  >(GetConnectReferrerInfoDocument, options);
}
export function useGetConnectReferrerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConnectReferrerInfoQuery,
    GetConnectReferrerInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConnectReferrerInfoQuery,
    GetConnectReferrerInfoQueryVariables
  >(GetConnectReferrerInfoDocument, options);
}
export type GetConnectReferrerInfoQueryHookResult = ReturnType<
  typeof useGetConnectReferrerInfoQuery
>;
export type GetConnectReferrerInfoLazyQueryHookResult = ReturnType<
  typeof useGetConnectReferrerInfoLazyQuery
>;
export type GetConnectReferrerInfoQueryResult = Apollo.QueryResult<
  GetConnectReferrerInfoQuery,
  GetConnectReferrerInfoQueryVariables
>;
export const GetFeaturedVehiclesDocument = gql`
  query GetFeaturedVehicles {
    getFeaturedVehicles {
      ...VehicleListData
    }
  }
  ${VehicleListDataFragmentDoc}
`;

/**
 * __useGetFeaturedVehiclesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedVehiclesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeaturedVehiclesQuery,
    GetFeaturedVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeaturedVehiclesQuery,
    GetFeaturedVehiclesQueryVariables
  >(GetFeaturedVehiclesDocument, options);
}
export function useGetFeaturedVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeaturedVehiclesQuery,
    GetFeaturedVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeaturedVehiclesQuery,
    GetFeaturedVehiclesQueryVariables
  >(GetFeaturedVehiclesDocument, options);
}
export type GetFeaturedVehiclesQueryHookResult = ReturnType<
  typeof useGetFeaturedVehiclesQuery
>;
export type GetFeaturedVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetFeaturedVehiclesLazyQuery
>;
export type GetFeaturedVehiclesQueryResult = Apollo.QueryResult<
  GetFeaturedVehiclesQuery,
  GetFeaturedVehiclesQueryVariables
>;
export const GetVehicleDocument = gql`
  query getVehicle($_id: ID, $slug: String) {
    getVehicle(_id: $_id, slug: $slug) {
      _id
      user {
        _id
        displayName
        username
        image
        idVerified
        primaryPhone
        subscription {
          type
          status
        }
        company {
          name
          logo
        }
      }
      brand
      pictures
      thumbs
      brand
      model
      price
      hidePrice
      currency
      year
      category
      plate
      mileageUnit
      mileage
      fuel
      transmission
      drivetrain
      color {
        interior
        exterior
      }
      location {
        country
        province
        municipality
      }
      condition
      description
      receivesAnotherVehicle
      negotiable
      rtv
      permit
      cc
      kwh
      soh
      cylinders
      range
      rangeMileageUnit
      equipment
      createdDate
      tags
      bumpCount
      listingTier
      expires
      renewedDate
      previousTier
      isContactInfoPublic
      receivesAnotherVehicle
      modelVersion
      priceIncludesImportTaxes
      priceIncludesTransfer
      includesFinancing
      financingData {
        entities
      }
      guarantee
      guaranteeDescription
      platePending
      youtubeLink
      slug
      minPrice
      totalFee
      sharedFee
      negotiationStatus
    }
  }
`;

/**
 * __useGetVehicleQuery__
 *
 * To run a query within a React component, call `useGetVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetVehicleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVehicleQuery,
    GetVehicleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehicleQuery, GetVehicleQueryVariables>(
    GetVehicleDocument,
    options
  );
}
export function useGetVehicleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVehicleQuery,
    GetVehicleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehicleQuery, GetVehicleQueryVariables>(
    GetVehicleDocument,
    options
  );
}
export type GetVehicleQueryHookResult = ReturnType<typeof useGetVehicleQuery>;
export type GetVehicleLazyQueryHookResult = ReturnType<
  typeof useGetVehicleLazyQuery
>;
export type GetVehicleQueryResult = Apollo.QueryResult<
  GetVehicleQuery,
  GetVehicleQueryVariables
>;
export const GetVehiclesDocument = gql`
  query getVehicles(
    $searchText: String
    $filters: VehicleFilters
    $page: Int
    $limit: Int
    $sort: Sort
    $random: Boolean
    $userSubscriptionTypes: [String]
    $connect: Boolean = false
  ) {
    getVehicles(
      searchText: $searchText
      filters: $filters
      page: $page
      limit: $limit
      sort: $sort
      random: $random
      userSubscriptionTypes: $userSubscriptionTypes
      connect: $connect
    ) {
      meta {
        total
        pageSize
      }
      data {
        ...VehicleListData
        minPrice @include(if: $connect)
        totalFee @include(if: $connect)
        sharedFee @include(if: $connect)
        negotiationStatus @include(if: $connect)
      }
    }
  }
  ${VehicleListDataFragmentDoc}
`;

/**
 * __useGetVehiclesQuery__
 *
 * To run a query within a React component, call `useGetVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehiclesQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      filters: // value for 'filters'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      random: // value for 'random'
 *      userSubscriptionTypes: // value for 'userSubscriptionTypes'
 *      connect: // value for 'connect'
 *   },
 * });
 */
export function useGetVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVehiclesQuery,
    GetVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(
    GetVehiclesDocument,
    options
  );
}
export function useGetVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVehiclesQuery,
    GetVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVehiclesQuery, GetVehiclesQueryVariables>(
    GetVehiclesDocument,
    options
  );
}
export type GetVehiclesQueryHookResult = ReturnType<typeof useGetVehiclesQuery>;
export type GetVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetVehiclesLazyQuery
>;
export type GetVehiclesQueryResult = Apollo.QueryResult<
  GetVehiclesQuery,
  GetVehiclesQueryVariables
>;
export const GetSimilarVehiclesDocument = gql`
  query getSimilarVehicles($_id: ID!, $filters: VehicleFilters) {
    getSimilarVehicles(_id: $_id, filters: $filters) {
      ...VehicleListData
    }
  }
  ${VehicleListDataFragmentDoc}
`;

/**
 * __useGetSimilarVehiclesQuery__
 *
 * To run a query within a React component, call `useGetSimilarVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarVehiclesQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetSimilarVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSimilarVehiclesQuery,
    GetSimilarVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSimilarVehiclesQuery,
    GetSimilarVehiclesQueryVariables
  >(GetSimilarVehiclesDocument, options);
}
export function useGetSimilarVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSimilarVehiclesQuery,
    GetSimilarVehiclesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSimilarVehiclesQuery,
    GetSimilarVehiclesQueryVariables
  >(GetSimilarVehiclesDocument, options);
}
export type GetSimilarVehiclesQueryHookResult = ReturnType<
  typeof useGetSimilarVehiclesQuery
>;
export type GetSimilarVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetSimilarVehiclesLazyQuery
>;
export type GetSimilarVehiclesQueryResult = Apollo.QueryResult<
  GetSimilarVehiclesQuery,
  GetSimilarVehiclesQueryVariables
>;
export const GetUserWatchlistDocument = gql`
  query getUserWatchlist($connect: Boolean = false) {
    getUserWatchlist(connect: $connect) {
      ...VehicleListData
      minPrice @include(if: $connect)
      totalFee @include(if: $connect)
      sharedFee @include(if: $connect)
      negotiationStatus @include(if: $connect)
    }
  }
  ${VehicleListDataFragmentDoc}
`;

/**
 * __useGetUserWatchlistQuery__
 *
 * To run a query within a React component, call `useGetUserWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserWatchlistQuery({
 *   variables: {
 *      connect: // value for 'connect'
 *   },
 * });
 */
export function useGetUserWatchlistQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserWatchlistQuery,
    GetUserWatchlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserWatchlistQuery, GetUserWatchlistQueryVariables>(
    GetUserWatchlistDocument,
    options
  );
}
export function useGetUserWatchlistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserWatchlistQuery,
    GetUserWatchlistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserWatchlistQuery,
    GetUserWatchlistQueryVariables
  >(GetUserWatchlistDocument, options);
}
export type GetUserWatchlistQueryHookResult = ReturnType<
  typeof useGetUserWatchlistQuery
>;
export type GetUserWatchlistLazyQueryHookResult = ReturnType<
  typeof useGetUserWatchlistLazyQuery
>;
export type GetUserWatchlistQueryResult = Apollo.QueryResult<
  GetUserWatchlistQuery,
  GetUserWatchlistQueryVariables
>;
export const GetWatchlistsDocument = gql`
  query getWatchlists($query: String) {
    getWatchlists(query: $query) {
      _id
    }
  }
`;

/**
 * __useGetWatchlistsQuery__
 *
 * To run a query within a React component, call `useGetWatchlistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetWatchlistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWatchlistsQuery,
    GetWatchlistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWatchlistsQuery, GetWatchlistsQueryVariables>(
    GetWatchlistsDocument,
    options
  );
}
export function useGetWatchlistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchlistsQuery,
    GetWatchlistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWatchlistsQuery, GetWatchlistsQueryVariables>(
    GetWatchlistsDocument,
    options
  );
}
export type GetWatchlistsQueryHookResult = ReturnType<
  typeof useGetWatchlistsQuery
>;
export type GetWatchlistsLazyQueryHookResult = ReturnType<
  typeof useGetWatchlistsLazyQuery
>;
export type GetWatchlistsQueryResult = Apollo.QueryResult<
  GetWatchlistsQuery,
  GetWatchlistsQueryVariables
>;
