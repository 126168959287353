import APP_CONFIG from "../app.config";
import { Vehicle } from "../graphql/generated/graphql";

export const canBeFinancedByCrediQ = (vehicle: Vehicle) => {
  return (
    APP_CONFIG.partners.crediq.enabled &&
    vehicle?.financingData?.entities?.includes("credi-q")
  );
  // return (
  //   APP_CONFIG.partners.crediq.enabled &&
  //   crediQFilters.yearMin <= vehicle.year &&
  //   crediQFilters.yearMax >= vehicle.year &&
  //   crediQFilters.brand.includes(vehicle.brand.toLowerCase()) &&
  //   crediQFilters.fuel.includes(vehicle.fuel) &&
  //   crediQFilters.category.includes(vehicle.category) &&
  //   crediQFilters.priceMax >= convertPriceToCRC(vehicle.price, vehicle.currency)
  // );
};
