const APP_CONFIG = {
  partners: {
    crediq: {
      enabled: false,
    },
    somit: {
      enabled: true,
    },
  },
};

export default APP_CONFIG;
