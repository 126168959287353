import { gql } from "apollo-server-micro";

const typeDefs = gql`
  type Financing {
    _id: ID!
    brand: String!
    model: String!
    downPayment: Float!
    term: Int!
    years: [Int!]!
    currentYear: Int!
  }

  extend type Query {
    getFinancing(
      vehicleId: String
      brand: String
      model: String
      year: Int
    ): Financing
    getFinancings(brand: String, model: String, year: Int): [Financing]
    # BOILERPLATER_INJECT_QUERY
  }

  extend type Mutation {
    addFinancing: Financing
    updateFinancing(_id: ID!): Financing
    deleteFinancing(_id: ID!): Financing
    # BOILERPLATER_INJECT_MUTATION
  }
`;

export default typeDefs;
