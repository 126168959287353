import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import APP_CONFIG from "../app.config";
import Glide from "@glidejs/glide";

import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

const partners = [
  {
    id: "crediq",
    href: "/crediq",
    enabled: APP_CONFIG.partners.crediq.enabled,
    banners: [
      "/partners/banner-crediq-mobile.jpg",
      "/partners/banner-crediq-tablet.jpg",
      "/partners/banner-crediq-desktop.jpg",
    ],
  },
  {
    id: "somit",
    href: "/somit",
    enabled: APP_CONFIG.partners.somit.enabled,
    banners: [
      "/partners/banner-somit-mobile.jpg",
      "/partners/banner-somit-tablet.jpg",
      "/partners/banner-somit-desktop.jpg",
    ],
  },
].filter((partner) => partner.enabled);

export default function ResponsiveBanner({ lock }: { lock?: string }) {
  const [glide, setGlide] = useState(null);
  const filteredPartners = lock
    ? partners.filter((p) => p.id === lock)
    : partners;
  useEffect(() => {
    if (!glide) {
      setGlide(
        new Glide(".glide.banners", {
          autoplay: 4500,
          rewind: true,
        })
      );
    }
  }, [glide]);

  useEffect(() => {
    if (glide && filteredPartners.length) {
      glide.mount();
      glide.update({
        startAt: Math.floor(Math.random() * filteredPartners.length),
      });
    }
  }, [glide]);

  if (!filteredPartners.length) return null;

  return (
    <div className="relative flex items-center justify-center sm:mx-auto sm:px-4 ">
      <div className="glide banners">
        <div data-glide-el="track" className="glide__track">
          <ul className="glide__slides">
            {filteredPartners.map((partner, idx) => {
              return (
                <li key={`slide-${partner.id}`} className="glide__slide">
                  <Link href={partner.href}>
                    <div className="relative mx-auto block md:hidden w-[320px] border border-gray-200 rounded-md">
                      <Image
                        src={partner.banners[0]}
                        className="object-center object-cover cursor-pointer sm:rounded-lg shadow-lg rounded-md"
                        alt="Banner de negocio en Movi"
                        // fill
                        // style={{
                        //   objectFit: "cover",
                        // }}
                        // fill
                        // style={{
                        //   objectFit: "cover",
                        // }}
                        height={320}
                        width={320}
                      />
                    </div>
                    <div className="relative mx-auto hidden md:block lg:hidden aspect-[2/1] h-72 w-full max-w-md border border-gray-200 rounded-md">
                      <Image
                        src={partner.banners[1]}
                        className="object-center object-cover cursor-pointer sm:rounded-lg shadow-lg rounded-md"
                        alt="Banner de negocio en Movi"
                        // fill
                        // style={{
                        //   objectFit: "cover",
                        // }}
                        fill
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="relative mx-auto aspect-[16/4] h-60 lg:w-full max-w-5xl hidden lg:block border border-gray-200 rounded-md">
                      <Image
                        src={partner.banners[2]}
                        className="object-center object-cover cursor-pointer sm:rounded-lg shadow-lg rounded-md"
                        alt="Banner de negocio en Movi"
                        // fill
                        // style={{
                        //   objectFit: "cover",
                        // }}
                        fill
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
