import { gql } from "apollo-server-micro";
import { SORT_KEYS } from "../../utils/constants";

const typeDefs = gql`
  enum Sort {
    ${SORT_KEYS.LATEST}
    ${SORT_KEYS.HIGHEST}
    ${SORT_KEYS.LOWEST}
  }

  input VehicleFilters {
    username: String
    category: [String]
    condition: [String]
    fuel: [String]
    transmission: [String]
    drivetrain: [String]
    priceMin: Int
    priceMax: Int
    currency: String
    yearMin: Int
    yearMax: Int
    brand: [String]
    model: String
    cc: Int
    cylinders: Int
    kwh: Int
    soh: Int
    range: Int
    province: [String]
    listingTier: [String]
  }

  type VehicleFiltersType {
    username: String
    search: [String]
    category: [String]
    condition: [String]
    fuel: [String]
    transmission: [String]
    drivetrain: [String]
    priceMin: Int
    priceMax: Int
    currency: String
    yearMin: Int
    yearMax: Int
    brand: [String]
    model: String
    cc: Int
    cylinders: Int
    kwh: Int
    soh: Int
    range: Int
    province: [String]
    listingTier: [String]
  }

  type SearchVehiclesMeta {
    total: Int
    pageSize: Int
  }

  type SearchVehiclesResponse {
    data: [Vehicle]
    meta: SearchVehiclesMeta
  }

  type Color {
    interior: String
    exterior: String
  }

  type FinancingData {
    entities: [String]
    monthlyFee: Float
    downPayment: Float
    term: Float
    financingCurrency: String

  }
  input FinancingDataInput {
    entities: [String]
    monthlyFee: Float
    downPayment: Float
    term: Float
    financingCurrency: String

  }

  input ColorInput {
    interior: String
    exterior: String
  }

  type Permit {
    taxes: Boolean
    label: Boolean
    technical: Boolean
  }

  input PermitInput {
    taxes: Boolean
    label: Boolean
    technical: Boolean
  }

  type Vehicle {
    _id: ID!
    userId: ID
    user: User
    pictures: [String]
    thumbs: [String]
    brand: String
    model: String
    price: Float
    hidePrice: Boolean
    currency: String
    year: Int
    yearStr: String
    category: String
    plate: String
    mileage: Int
    mileageUnit: String
    fuel: String
    transmission: String
    drivetrain: String
    color: Color
    location: Location
    condition: String
    description: String
    receivesAnotherVehicle: Boolean
    negotiable: Boolean
    rtv: Boolean
    permit: Boolean,
    # permits: Permit
    cc: Int
    cylinders: Int
    kwh: Float
    soh: Int
    range: Int
    rangeMileageUnit: String
    equipment: [String]
    tags: String
    listingTier: String
    purchaseId: ID
    status: String
    deletionCause: String
    createdDate: Float
    lastUpdated: Float
    bumpCount: Int
    expires: Float
    renewedDate: Float
    renewCount: Int
    previousTier: String
    isContactInfoPublic: Boolean
    modelVersion: String
    priceIncludesImportTaxes: Boolean
    priceIncludesTransfer: Boolean
    includesFinancing: Boolean
    financingData: FinancingData 
    guarantee: Boolean
    guaranteeDescription: String
    platePending: Boolean
    youtubeLink: String
    isFeatured: Boolean
    slug: String
    # Connect specific properties
    minPrice: Float
    totalFee: Float
    sharedFee: Float
    negotiationStatus: String
    # PROPERTIES HERE
  }

  input VehicleInput {
    _id: ID
    userId: ID
    pictures: [String]
    brand: String
    model: String
    price: Float
    hidePrice: Boolean
    currency: String
    year: Int
    category: String
    plate: String
    mileage: Int
    mileageUnit: String
    fuel: String
    transmission: String
    drivetrain: String
    color: ColorInput
    province: String
    municipality: String
    condition: String
    description: String
    receivesAnotherVehicle: Boolean
    negotiable: Boolean
    rtv: Boolean
    permit: Boolean,
    # permits: PermitInput
    cc: Int
    cylinders: Int
    kwh: Int
    soh: Int
    range: Int
    rangeMileageUnit: String
    equipment: [String]
    tags: String
    listingTier: String
    purchaseId: ID
    isContactInfoPublic: Boolean
    modelVersion: String
    priceIncludesImportTaxes: Boolean
    priceIncludesTransfer: Boolean
    includesFinancing: Boolean
    financingData: FinancingDataInput 
    guarantee: Boolean
    guaranteeDescription: String
    platePending: Boolean
    youtubeLink: String
    slug: String
     # Connect specific properties
    minPrice: Float
    totalFee: Float
    sharedFee: Float
    negotiationStatus: String
    # PROPERTIES HERE
  }

  extend type Query {
    getVehicle(
      _id: ID 
      slug: String
      # PROPERTIES HERE
    ): Vehicle
    getVehicles(
      searchText: String
      filters: VehicleFilters
      page: Int
      limit: Int
      sort: Sort
      random: Boolean
      userSubscriptionTypes: [String]
      connect: Boolean!
    ): SearchVehiclesResponse
    # BOILERPLATER_INJECT_QUERY
    getSimilarVehicles(
      _id: ID
      filters: VehicleFilters
    ): [Vehicle]
    getFeaturedVehicles: Vehicle
  }

  extend type Mutation {
    addVehicle(
      vehicle: VehicleInput!
    ): Vehicle
    updateVehicle(
      vehicle: VehicleInput!
    ): Vehicle
    deleteVehicle(_id: ID!, deletionCause: String): Vehicle
    # BOILERPLATER_INJECT_MUTATION
    bumpVehicle(
      # PROPERTIES HERE
      _id: ID!
    ): Vehicle
    renewVehicle(
      _id: ID!
      tier: String!
    ): Vehicle
  }
`;

export default typeDefs;
